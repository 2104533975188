import React from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';

// List of shortcuts to show in the overview.
//
const shortcutList = [
  {
    title: 'Minimize timeline',
    editorType: 'both',
    shortcuts: { mac: 'CMD+1 | Shift+T', PC: 'Ctrl+1 | Shift+T' }
  },
  {
    title: 'Toggle overflow',
    editorType: 'both',
    shortcuts: { mac: 'CMD+2 | Shift+O', PC: 'Ctrl+2 | Shift+O' }
  },
  {
    title: 'Toggle grid',
    editorType: 'both',
    shortcuts: { mac: 'CMD+3 | Shift+G', PC: 'Ctrl+3 | Shift+G' }
  },
  {
    title: 'Toggle snapping',
    editorType: 'both',
    shortcuts: { mac: 'CMD+4 | Shift+S', PC: 'Ctrl+4 | Shift+S' }
  },
  {
    title: 'Toggle ruler',
    editorType: 'both',
    shortcuts: { mac: 'CMD+5 | Shift+R', PC: 'Ctrl+5 | Shift+R' }
  },

  {
    title: 'Zoom in/out',
    editorType: 'both',
    shortcuts: { mac: 'CMD +/- | CMD+Scroll', PC: 'Ctrl +/- | Ctrl+Scroll' }
  },

  {
    title: 'Zoom reset',
    editorType: 'both',
    shortcuts: { mac: 'CMD+0', PC: 'Ctrl+0' }
  },
  { title: 'Save', editorType: 'both', shortcuts: { mac: 'CMD+S', PC: 'Ctrl+S' } },
  { title: 'Undo', editorType: 'both', shortcuts: { mac: 'CMD+Z', PC: 'Ctrl+Z' } },
  {
    title: 'Redo',
    editorType: 'both',
    shortcuts: { mac: 'CMD+Shift+Z | CMD+Y', PC: 'Ctrl+Shift+Z | Ctrl+Y' }
  },

  {
    title: 'Copy animation/layer',
    editorType: 'template',
    shortcuts: { mac: 'CMD+C', PC: 'Ctrl+C' }
  },
  {
    title: 'Paste animation/layer',
    editorType: 'template',
    shortcuts: { mac: 'CMD+V', PC: 'Ctrl+V' }
  },
  {
    title: 'Duplicate animation/layer',
    editorType: 'template',
    shortcuts: { mac: 'CMD+D', PC: 'Ctrl+D' }
  },
  { title: 'Create group', editorType: 'template', shortcuts: { mac: 'CMD+G', PC: 'Ctrl+G' } },

  {
    title: 'Move layer (1px)',
    editorType: 'both',
    shortcuts: { mac: 'Arrow keys', PC: 'Arrow keys' }
  },
  {
    title: 'Move layer (10px)',
    editorType: 'both',
    shortcuts: { mac: 'Shift + Arrow keys', PC: 'Shift + Arrow keys' }
  },
  {
    title: 'Rotate layer',
    editorType: 'both',
    shortcuts: { mac: 'CMD+Drag corner', PC: 'Ctrl+Drag corner' }
  },
  {
    title: 'Resize layer (keep ratio)',
    editorType: 'both',
    shortcuts: { mac: 'Shift+Drag corner', PC: 'Shift+Drag corner' }
  },
  {
    title: 'Move canvas',
    editorType: 'both',
    shortcuts: { mac: 'H+Drag canvas', PC: 'H+Drag canvas' }
  },
  {
    title: 'Move canvas',
    editorType: 'both',
    shortcuts: { mac: 'Middle Mouse Button', PC: 'Middle Mouse Button' }
  },
  {
    title: 'Delete animation/layer',
    editorType: 'both',
    shortcuts: { mac: 'Delete | Backspace', PC: 'Delete | Backspace' }
  },
  {
    title: 'Play/Pause animations',
    editorType: 'both',
    shortcuts: { mac: 'Space', PC: 'Space' }
  },
  {
    title: 'Stop animations',
    editorType: 'both',
    shortcuts: { mac: 'Shift+Space', PC: 'Shift+Space' }
  }
];

// Function for getting the current operating system.
// We only change from PC if it's a mac device.
function getOperatingSystem() {
  let platform = 'PC';

  if (
    'userAgentData' in window.navigator &&
    'platform' in window.navigator.userAgentData &&
    window.navigator.userAgentData.platform.includes('mac')
  ) {
    platform = 'mac';
  }

  return platform;
}

// Generation of all our shortcuts
const ShortcutRow = styled.div`
  display: flex;
  width: 330px;
  place-content: space-between;
  height: 25px;
  align-items: center;
`;

const ShortcutText = styled.span`
  font-size: 12px;
`;
const getShortcuts = () => {
  const editorType = useSelector((state) => state.editorSession.editorType);

  const operatingSystem = getOperatingSystem();

  return shortcutList.map(
    (shortcut, index) =>
      (shortcut.editorType === 'both' || shortcut.editorType === editorType) && (
        <ShortcutRow key={`${shortcut.title}-${index}`}>
          <ShortcutText>{shortcut.title}</ShortcutText>
          <ShortcutText>{shortcut.shortcuts[operatingSystem]}</ShortcutText>
        </ShortcutRow>
      )
  );
};

// The shortcuts menu overview
const ShortcutOverview = styled.div`
  right: 151px;

  top: 54px;
  position: absolute;
  background-color: #242424;
  border: 1px solid #3c3c3c;
  z-index: 100;
  padding: 7px 14px;
`;

function ShortcutsMenu(props) {
  const { show } = props;

  return (
    show && (
      <ShortcutOverview id='top-bar-shortcuts-tooltip-popup'>{getShortcuts()}</ShortcutOverview>
    )
  );
}

export default ShortcutsMenu;
