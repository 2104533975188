import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import toast from 'react-hot-toast';
import { createTemplate } from '../../../redux/slices/dashboard';
import usePosthogCapture from '../../../../utils/hooks/usePosthogCapture';

import { CloseIcon } from '../../../assets/icons';
import BaseButton from '../../common/BaseButton';
import CloseCross from '../../common/styled/CloseCross';

const buttonStyle = {
  backgroundColor: '#4792E2',
  width: 200,
  height: 40,
  fontSize: 14,
  lineHeight: 17
};

const FormCard = styled.div`
  z-index: 100;
  position: absolute;
  left: 70px;
  top: 75px;
  width: 300px;
  margin: 8px;
  background: ${(props) => props.theme.colors.backgroundColorSecondary};
  border-radius: 4px;
  border: 1px solid ${(props) => props.theme.colors.borderColor};
  height: 147px;
`;

const FormContent = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  height: 100%;
  align-items: center;
  margin: 30px 12px 12px;
`;

function CreateTemplateForm(props) {
  const { closeFunc } = props;
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const capturePosthogData = usePosthogCapture();
  const [name, setName] = useState('');
  const templateForm = useRef();

  const submit = async (event) => {
    event.preventDefault();
    if (!name) {
      toast.error('Template must have a name');
      return;
    }

    capturePosthogData({ event: 'create-template', type: 'template' });

    const data = {
      title: name,
      objectData: {
        fonts: [],
        images: [],
        settings: {
          clicktag: '',
          repeatFrom: 0,
          repeats: 3,
          duration: 10,
          linked_clicktags: true
        },
        template_version: 9
      }
    };

    dispatch(createTemplate({ data, navigate }));
    closeFunc();
    setName('');
  };

  const handleChangeName = (event) => {
    setName(event.target.value);
  };

  useEffect(() => {
    templateForm.current.focus();
  }, []);

  return (
    <FormCard data-cy='create-template-form'>
      <div className='template'>
        <CloseCross
          onClick={() => {
            closeFunc();
          }}>
          <CloseIcon />
        </CloseCross>
        <FormContent className='template-wrapper'>
          <form onSubmit={submit}>
            <div className='input-element'>
              <input
                ref={templateForm}
                className='input'
                type='text'
                placeholder='Name'
                value={name}
                onChange={handleChangeName}
              />
            </div>
            <BaseButton
              text='Create template'
              style={buttonStyle}
              onClick={submit}
              id='create-template'
            />
          </form>
        </FormContent>
      </div>
    </FormCard>
  );
}

CreateTemplateForm.propTypes = {
  closeFunc: PropTypes.func.isRequired
};

export default CreateTemplateForm;
