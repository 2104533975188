import React, { useEffect, useState } from 'react';
import * as Sentry from '@sentry/react';
import TagManager from 'react-gtm-module';
import { useKeycloak } from '@react-keycloak/web';
import posthog from 'posthog-js';
import { useDispatch, useSelector } from 'react-redux';
import { getUserAccounts } from './utils/userAccounts';
import { PageLoader } from './studioEditor/components/PageLoader';
import { NoStudioAccess } from './studioEditor/components/NoStudioAccess';
import Routing from './routing';
import { setCurrentAccountId, setUser } from './studioEditor/redux/slices/auth';
import useAuthorization from './utils/hooks/useAuthorization';
import { NoStudioPermission } from './studioEditor/components/NoStudioPermission';
import * as api from './studioEditor/api';

function AuthenticatedApp() {
  const { initialized, keycloak } = useKeycloak();
  const [isPosthogReady, setIsPosthogReady] = useState(false);
  const currentAccountId = useSelector((state) => state.auth.currentAccountId);
  const user = useSelector((state) => state.auth.user);
  const [account, setAccount] = useState(null);
  const dispatch = useDispatch();
  const isAuthorized = useAuthorization();

  const handleGtmDatalayer = (
    id,
    name,
    email,
    createdAt,
    accountId,
    accountName,
    accountCreatedAt,
    accountPlan
  ) => {
    TagManager.dataLayer({
      dataLayer: {
        event: 'setZuuviUserData',
        userId: id,
        userName: name,
        userEmail: email,
        userCreatedAt: createdAt,
        accountId,
        accountName,
        accountCreatedAt,
        accountPlan
      },
      dataLayerName: 'PageDataLayer'
    });
  };

  useEffect(() => {
    if (initialized && !keycloak.authenticated) {
      keycloak.login();
    }
  }, [initialized, keycloak]);

  useEffect(async () => {
    if (keycloak.tokenParsed) {
      const { data: userData } = await api.accountService.getUser(
        keycloak.tokenParsed.sub,
        keycloak.token
      );
      dispatch(setUser(userData));
    }
  }, [keycloak.tokenParsed]);

  useEffect(() => {
    if (user) {
      const userAccounts = user.accounts;
      if (
        !currentAccountId ||
        (userAccounts &&
          userAccounts.length > 0 &&
          !userAccounts.some((userAccount) => userAccount.account.id === currentAccountId))
      ) {
        dispatch(setCurrentAccountId(userAccounts[0].account.id));
      }
    }
  }, [currentAccountId, user]);

  useEffect(() => {
    if (user && currentAccountId) {
      const userAccount = user.accounts.find((usrAcc) => usrAcc.account.id === currentAccountId);
      if (userAccount) {
        setAccount(userAccount.account);
      }
    }
  }, [user, currentAccountId]);

  useEffect(() => {
    if (user && account) {
      const fullName = `${user.firstName} ${user.lastName}`;
      const data = {
        id: user.id,
        username: fullName,
        email: user.email,
        client_id: account.id,
        account_id: account.id,
        user_id: user.id,
        ip_address: '{{auto}}',
        impersonated: !keycloak.tokenParsed.auth_time
      };

      Sentry.setUser(data);

      posthog.identify(user.id, { user_id: user.id, account_id: account.id });
      posthog.people.set({ email: user.email });
      posthog.reloadFeatureFlags();

      posthog.onFeatureFlags(() => {
        if (!isPosthogReady) setIsPosthogReady(true);

        if (!isAuthorized({ permission: 'studio' })) {
          return <NoStudioAccess />;
        }

        if (isAuthorized({ featureFlag: 'posthog-record-session' })) {
          posthog.startSessionRecording();
        }
      });

      handleGtmDatalayer(
        user.id,
        fullName,
        user.email,
        Math.floor(new Date(user.createdAt).getTime() / 1000),
        account.id,
        account.accountName,
        Math.floor(new Date(account.createdAt).getTime() / 1000),
        account.planType
      );
    }
  }, [user, account]);

  if (!initialized) {
    return <PageLoader />;
  }

  if (!keycloak.authenticated) {
    return <NoStudioAccess />;
  }

  if (!user) {
    return <PageLoader />;
  }

  if (!currentAccountId) {
    const userAccounts = getUserAccounts();
    if (userAccounts.length === 0) {
      return <NoStudioAccess />;
    }
    return <PageLoader />;
  }

  return (
    // Should be added at a later point, when dependencies are up to date with react 18
    // <React.StrictMode>
    <Routing />
    // </React.StrictMode>
  );
}

export default AuthenticatedApp;
