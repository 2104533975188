/* eslint-disable no-restricted-syntax */
/* eslint-disable no-use-before-define */
/* eslint-disable no-undef */
import { createSlice, current } from '@reduxjs/toolkit';
import { v4 as uuidv4 } from 'uuid';
import _ from 'lodash';
import { getPathById } from '../../assets/utils';
import { AUTOSAVE_TEMPLATE_SUCCESS } from '../actionTypeConstants';
import { upgradeTemplate } from '../../../utils/templateUpgrader';
import { updateImageState, findLayer } from '../../../helpers';
import { selectFormat } from './editorSession';

const initialState = {
  title: 'untitled',
  object_data: {
    clicktags: [],
    layers: [],
    settings: {
      clicktag: '',
      repeats: 3,
      duration: 10,
      repeatFrom: 0,
      linked_clicktags: true
    }
  },
  formats: [],
  public: false,
  tags: [],
  _history: {
    undo: [],
    current: null,
    redo: [],
    lastestActionType: false,
    latestActionTime: 0
  }
};

const allowedOnFormat = {
  format: {
    x: true,
    y: true,
    width: true,
    height: true,
    radius: true,
    rotation: true,
    opacity: true,
    background_color: true,
    border: {
      top: true,
      left: true,
      bottom: true,
      right: true,
      color: true
    },
    box_shadow: {
      x: true,
      y: true,
      color: true,
      inset: true,
      blur: true,
      spread: true
    },
    padding: {
      top: true,
      right: true,
      bottom: true,
      left: true
    },
    text_shadow: {
      color: true,
      x: true,
      y: true,
      blur: true
    },
    text_overflow: true,
    text_overflow_max_lines: true,
    font_color: true,
    text_alignment: true,
    font_weight: true,
    font_style: true,
    font_size: true,
    line_height: true,
    text_transform: true,
    letter_spacing: true,
    align_items: true,
    justify_content: true
  },
  settings: {
    font: true,
    font_family: true,
    source: true,
    ignored: true,
    text: true,
    imageState: true
  }
};

const template = createSlice({
  name: 'template',
  initialState,
  extraReducers: (builder) => {
    builder.addCase(AUTOSAVE_TEMPLATE_SUCCESS, (state, action) => {
      state.updated_at = action.payload.updated_at;
    });

    builder.addCase(selectFormat.type, (state, action) => {
      let selectedFormatId = action.payload;

      if (selectedFormatId && typeof selectedFormatId === 'object') {
        selectedFormatId = selectedFormatId.id;
      }

      const templateLayers = state.object_data.layers;
      const { formats } = state;

      if (!('layers' in state.object_data)) state.object_data.layers = [];

      // Dont deepcopy here in console.log
      state = checkForMissingSettings(state, selectedFormatId);
    });
    builder.addMatcher(
      (action) => 'meta' in action && action.meta.saveHistory,
      (state, action) => {
        const newLatestActionTime = Date.now();
        if (
          newLatestActionTime - state._history.lastestActionTime > 250 ||
          state._history.lastestActionType !== action.type
        ) {
          const historyObject = makeHistoryObject(state);
          if (state._history.current) {
            // Only use history if the saved object is useful
            state._history.undo = [...state._history.undo, state._history.current].splice(-10, 10); // Limit undo to 10.
          }
          state._history.current = historyObject;
        }
        state._history.lastestActionTime = newLatestActionTime;
        state._history.lastestActionType = action.type;
      }
    );
  },
  reducers: {
    dummyPrepareReducer: {
      reducer: (state, action) => {},
      prepare: (arg1, arg2) => ({
        meta: {
          saveHistory: false
        },
        payload: {
          arg1,
          arg2
        }
      })
    },
    dummyReducer(state, action) {
      state.edit = action.payload.edit;
    },

    // Creative Set
    getCreativeSet() {},
    saveCreativeSet: {
      reducer: () => {},
      prepare: (creativeSetId, callback, failCallback) => ({
        meta: {
          saveHistory: false
        },
        payload: {
          creativeSetId,
          callback,
          failCallback
        }
      })
    },
    publishCreativeSet: {
      reducer: () => {},
      prepare: (creativeSetId, callback, failCallback) => ({
        meta: {
          saveHistory: false
        },
        payload: {
          creativeSetId,
          callback,
          failCallback
        }
      })
    },
    // template
    getAd() {},
    saveAd: {
      reducer: () => {},
      prepare: (adId, callback, failCallback) => ({
        meta: {
          saveHistory: false
        },
        payload: {
          adId,
          callback,
          failCallback
        }
      })
    },
    publishAd: {
      reducer: () => {},
      prepare: (adId, callback, failCallback) => ({
        meta: {
          saveHistory: false
        },
        payload: {
          adId,
          callback,
          failCallback
        }
      })
    },
    getTemplate() {},
    setTemplate(state, action) {
      let templateNew = action.payload;

      if (templateNew !== null) {
        templateNew = upgradeTemplate(templateNew);
      }

      return checkForMissingSettings({
        ...initialState,
        ...templateNew
      });
    },
    setTemplateRepeatFrom: {
      reducer: (state, action) => {
        const { repeatFrom } = action.payload;
        state.object_data.settings.repeatFrom = repeatFrom;
      },
      prepare: (repeatFrom) => ({
        meta: {
          saveHistory: true
        },
        payload: {
          repeatFrom
        }
      })
    },
    setTemplateRotateOnRepeat: {
      reducer: (state, action) => {
        const { rotateOnRepeat } = action.payload;
        state.object_data.settings.rotateOnRepeat = rotateOnRepeat;
      },
      prepare: (rotateOnRepeat) => ({
        meta: {
          saveHistory: true
        },
        payload: {
          rotateOnRepeat
        }
      })
    },
    setTemplateDuration: {
      reducer: (state, action) => {
        const { duration, flatLayers } = action.payload;

        const animationArr = flatLayers.reduce((arr, layer) => {
          arr.push(...layer.animations);
          return arr;
        }, []);
        const updatedDuration = updateFormatDuration(animationArr, duration);
        if (!('settings' in state.object_data)) state.object_data.settings = {};
        if ('repeatFrom' in state.object_data.settings) {
          if (state.object_data.settings.repeatFrom >= updatedDuration / 100) {
            state.object_data.settings.repeatFrom = updatedDuration / 100 - 0.1;
          }
        }
        state.object_data.settings.duration = updatedDuration / 100;
      },
      prepare: (duration, flatLayers) => ({
        meta: {
          saveHistory: true
        },
        payload: {
          duration,
          flatLayers
        }
      })
    },
    saveTemplate() {},
    setTemplateRepeats: {
      reducer: (state, action) => {
        state.object_data.settings.repeats = action.payload.repeats;
      },
      prepare: (repeats) => ({
        meta: {
          saveHistory: true
        },
        payload: {
          repeats
        }
      })
    },
    setTemplateTitle: {
      reducer: (state, action) => {
        state.title = action.payload.title;
      },
      prepare: (title) => ({
        meta: {
          saveHistory: true
        },
        payload: {
          title
        }
      })
    },
    setTemplateClicktag: {
      reducer: (state, action) => {
        if (!('settings' in state.object_data)) {
          state.object_data.settings = {};
        }
        const clicktag = action.payload.clicktag.trim().replace(/[\n\t]+/g, '');
        const isLinkedClicktag = state.object_data?.settings?.linked_clicktags ?? true;
        state.object_data.settings.clicktag = clicktag;
        if (isLinkedClicktag && !('linked_clicktags' in state.object_data.settings)) {
          state.object_data.settings.linked_clicktags = true;
        }
        for (const format of state.formats) {
          // We're deleting all clicktags on the formats if they are linked.
          if (format.object_data.settings && isLinkedClicktag) {
            delete format.object_data.settings.clicktag;
          } else if (format.id === action.payload.selectedFormatId) {
            if (!('settings' in format.object_data)) {
              format.object_data.settings = {};
            }
            if (!('clicktag' in format.object_data.settings)) {
              format.object_data.settings.clicktag = '';
            }
            format.object_data.settings.clicktag = clicktag;
          }
        }
      },
      prepare: (clicktag, selectedFormatId) => ({
        meta: {
          saveHistory: true
        },
        payload: {
          clicktag,
          selectedFormatId
        }
      })
    },
    setTemplateLinkedClicktags: {
      reducer: (state, action) => {
        if (!('settings' in state.object_data)) {
          state.object_data.settings = {};
        }
        state.object_data.settings.linked_clicktags = action.payload.linkClicktags;
        if (action.payload.linkClicktags) {
          state.formats.forEach((format) => {
            if (format.id === action.payload.selectedFormatId) {
              if (format.object_data?.settings?.clicktag) {
                state.object_data.settings.clicktag = format.object_data.settings.clicktag;
              }
              if (format.object_data?.clicktags) {
                state.object_data.clicktags = format.object_data.clicktags;
              }
            }
            delete format.object_data?.settings?.clicktag;
            delete format.object_data?.clicktags;
          });
        } else {
          state.formats.forEach((format) => {
            if (format.id === action.payload.selectedFormatId) {
              if (format.object_data?.settings?.clicktag) {
                state.object_data.settings.clicktag = format.object_data.settings.clicktag;
              }
              if (format.object_data?.clicktags) {
                state.object_data.clicktags = format.object_data.clicktags;
              }
            }

            if (!('settings' in format.object_data)) {
              format.object_data.settings = {};
            }
            format.object_data.settings.clicktag = state.object_data?.settings?.clicktag;
            format.object_data.clicktags = state.object_data?.clicktags
              ? [...state.object_data.clicktags]
              : [];
          });

          delete state.object_data?.settings?.clicktag;
          delete state.object_data?.clicktags;
        }
      },
      prepare: (linkClicktags, selectedFormatId) => ({
        meta: {
          saveHistory: true
        },
        payload: {
          linkClicktags,
          selectedFormatId
        }
      })
    },
    // format
    addFormat(state, action) {
      const { format, originalFormatId } = action.payload;
      if (format) {
        format.object_data.images = [];
        format.object_data.videos = [];
        format.object_data.fonts = [];
        // We add all used images and remove the unused ones afterwards.
        for (const originalFormat of state.formats) {
          if (originalFormat.object_data.images) {
            format.object_data.images = _.unionBy(
              format.object_data.images,
              originalFormat.object_data.images,
              'uuid'
            );
          }
          if (originalFormat.object_data.videos) {
            format.object_data.videos = _.unionBy(
              format.object_data.videos,
              originalFormat.object_data.videos,
              'uuid'
            );
          }
          if (originalFormat.object_data.fonts) {
            format.object_data.fonts = _.unionBy(
              format.object_data.fonts,
              originalFormat.object_data.fonts,
              'uuid'
            );
          }
        }
        state.formats.push(format);
        format.object_data.images = updateImagesArray(
          format.object_data.images,
          state.object_data.layers,
          format
        );
        format.object_data.videos = updateVideosArray(
          format.object_data.videos,
          state.object_data.layers,
          format
        );
        format.object_data.fonts = updateFontsArray(
          format.object_data.fonts,
          state.object_data.layers,
          format
        );
      }
    },
    removeFormat(state, action) {
      const formatId = action.payload;
      const indexOfFormat = state.formats.findIndex((format) => format.id === formatId);
      state.formats.splice(indexOfFormat, 1);
    },
    setFormatName(state, action) {
      const { formatId, title } = action.payload;
      const indexOfFormat = state.formats.findIndex((format) => format.id === formatId);
      state.formats[indexOfFormat].title = title;
    },
    setFormatSizeEstimate(state, action) {
      const { formatId, size } = action.payload;
      const indexOfFormat = state.formats.findIndex((format) => format.id === formatId);
      state.formats[indexOfFormat].size = size;
    },

    // layers
    addLayer: {
      reducer: (state, action) => {
        const newLayer = action.payload.layer;

        if (action.payload.font) {
          newLayer.settings = {};
          const textFont = action.payload.font;
          newLayer.settings.font = textFont.uuid;
          for (const format of state.formats) {
            let isNewFont = true;
            if (!format.object_data.fonts) format.object_data.fonts = [];
            for (const font of format.object_data.fonts) {
              if (font.uuid === textFont.uuid) {
                isNewFont = false;
                break;
              }
            }
            if (isNewFont) {
              format.object_data.fonts.push(textFont);
            }
          }
        }

        if (action.payload.text) {
          newLayer.settings.text = action.payload.text;
        }

        if (action.payload.image) {
          const newImage = {
            uuid: action.payload.image.uuid,
            source: action.payload.image.url
          };
          for (const format of state.formats) {
            let isNewImage = true;
            if (!format.object_data.images) format.object_data.images = [];
            for (const image of format.object_data.images) {
              if (image.uuid === newImage.uuid) {
                isNewImage = false;
                break;
              }
            }
            if (isNewImage) {
              format.object_data.images.push(newImage);
            }
          }
        }

        if (action.payload.video) {
          const newVideo = {
            uuid: action.payload.video.uuid,
            source: action.payload.video.url
          };
          for (const format of state.formats) {
            let isNewVideo = true;
            if (!format.object_data.videos) format.object_data.videos = [];
            for (const video of format.object_data.videos) {
              if (video.uuid === newVideo.uuid) {
                isNewVideo = false;
                break;
              }
            }
            if (isNewVideo) {
              format.object_data.videos.push(newVideo);
            }
          }
        }

        if (!('layers' in state.object_data)) {
          state.object_data.layers = [];
        }

        if (!('name' in newLayer) || newLayer.name === '') {
          const layerNumber = state.object_data.layers.length + 1;
          newLayer.name =
            newLayer.type === 'group' ? `group ${layerNumber}` : `layer ${layerNumber}`;
        }

        if (action.payload.formatId) {
          for (const format of state.formats) {
            format.object_data.layers[newLayer.uuid] = {};
          }
        }
        state.object_data.layers.unshift(newLayer);
        state = checkForMissingSettings(state, action.payload.formatId, newLayer.id);
        state = recalculateGroups(state, action.payload.formatId);
      },
      prepare: (objectType, formatId = false) => {
        const uuid = uuidv4();
        const newLayer = {
          ...objectType,
          animations: [],
          id: `${uuid}`,
          uuid: `${uuid}`
        };
        if (objectType.animations?.length > 0) {
          for (const animation of objectType.animations) {
            animation.target = uuid;
            animation.uuid = uuidv4();
            newLayer.animations.push(animation);
          }
        }
        const payload = {
          layer: newLayer
        };
        if (objectType.type === 'text') {
          payload.font = objectType.settings.font;
          payload.text = objectType.settings.text;
        } else if (objectType.type === 'image') {
          newLayer.settings = {
            source: objectType.settings.source.uuid,
            imageState: objectType.settings.imageState
          };
          payload.image = objectType.settings.source;
        } else if (objectType.type === 'video') {
          newLayer.settings = {
            source: objectType.settings.source.uuid
          };
          payload.video = objectType.settings.source;
        }
        payload.formatId = formatId;
        return {
          meta: {
            saveHistory: true
          },
          payload
        };
      }
    },
    addGroupLayer: {
      reducer: (state, action) => {
        const newLayer = action.payload.layer;
        const childrenIds = action.payload.children;

        const moveLayers = (layers) => {
          for (let i = layers.length - 1; i >= 0; i--) {
            const layer = layers[i];
            if (childrenIds.includes(layer.id)) {
              layers.splice(i, 1);
              newLayer.layers.unshift(layer);
            } else if (layer.type === 'group') {
              moveLayers(layer.layers);
            }
          }
        };
        moveLayers(state.object_data.layers);

        if (!('name' in newLayer) || newLayer.name === '') {
          const layerNumber = state.object_data.layers.length + 1;
          newLayer.name =
            newLayer.type === 'group' ? `group ${layerNumber}` : `layer ${layerNumber}`;
        }

        state.object_data.layers.unshift(newLayer);
        state = recalculateGroups(state, action.payload.formatId);
      },
      prepare: (groupObject, formatId = null, children = []) => {
        const uuid = uuidv4();
        const newLayer = {
          ...groupObject,
          animations: [],
          id: `${uuid}`,
          uuid: `${uuid}`
        };
        const payload = {
          layer: newLayer,
          children,
          formatId
        };
        return {
          meta: {
            saveHistory: true
          },
          payload
        };
      }
    },
    reorderLayers: {
      reducer: (state, action) => {
        // Make "flat" data, for easier access
        const flattenLayers = (layers) => {
          let flatLayers = {};
          for (const layer of layers) {
            if ('layers' in layer) {
              flatLayers = Object.assign(flatLayers, flattenLayers(layer.layers));
            }
            flatLayers[layer.uuid] = layer;
          }
          return flatLayers;
        };
        const layerRefs = flattenLayers(state.object_data.layers);

        // Reorder the layers
        const makeNewLayers = (layers) => {
          const newLayers = [];
          for (const layer of layers) {
            const l = layerRefs[layer.uuid];
            if ('children' in layer) {
              l.layers = makeNewLayers(layer.children);
            }
            if ('format' in layer) {
              l.format = {
                ...l.format,
                ...layer.format
              };
            }
            newLayers.push(l);
            /*
            if (layer.dx || layer.dy) {
              for (let format of state.formats) {
                if (
                  layer.uuid in format.object_data.layers &&
                  "format" in format.object_data.layers[layer.uuid]
                ) {
                  // format.object_data.layers[layer.uuid].format.x = format.object_data.layers[layer.uuid].format.x ? format.object_data.layers[layer.uuid].format.x + layer.dx : l.format.x + layer.dx;
                  // format.object_data.layers[layer.uuid].format.y = format.object_data.layers[layer.uuid].format.y ? format.object_data.layers[layer.uuid].format.y + layer.dy : l.format.y + layer.dy;
                }
              }
            }
            */
          }
          return newLayers;
        };
        // const newLayers = makeNewLayers(deepCopyObject(action.payload.layers)); // Do we need to deep copy the layers?
        const newLayers = makeNewLayers(action.payload.layers);
        state.object_data.layers = newLayers;

        state = recalculateGroupOrder(
          state,
          deepCopyObject(action.payload.oldLayers),
          deepCopyObject(action.payload.dragItem),
          action.payload.formatId
        );
      },
      prepare: (layers, oldLayers, dragItem, formatId) => ({
        meta: {
          saveHistory: true
        },
        payload: {
          layers,
          oldLayers,
          dragItem,
          formatId
        }
      })
    },
    updateLayerFont: {
      reducer: (state, action) => {
        const { layerId, formatId, fontObject } = action.payload;

        if (fontObject) {
          for (const format of state.formats) {
            // TODO: this is commented out so the fonts are applied to every format
            // if (formatId === format.id) {
            let isNewFont = true;
            if (!format.object_data.fonts) format.object_data.fonts = [];
            for (const font of format.object_data.fonts) {
              if (font.uuid === fontObject.uuid) {
                isNewFont = false;
                break;
              }
            }
            if (isNewFont) {
              format.object_data.fonts.push(fontObject);
            }
            // }
          }
        }

        const changeLayerFont = (layers) => {
          for (const layer of layers) {
            if (layer.uuid === layerId) {
              if (!('settings' in layer)) layer.settings = {};
              layer.settings.font = fontObject.uuid;
              for (const format of state.formats) {
                const formatLayer = format.object_data.layers[layer.uuid];
                if (!('settings' in formatLayer)) formatLayer.settings = {};
                formatLayer.settings.font = fontObject.uuid;
              }
              break;
            }
            if (layer.layers && layer.layers.length > 0) {
              changeLayerFont(layer.layers);
            }
          }
        };
        changeLayerFont(state.object_data.layers);

        for (const format of state.formats) {
          format.object_data.fonts = updateFontsArray(
            format.object_data.fonts,
            state.object_data.layers,
            format
          );
        }
      },
      prepare: (layerId, formatId, fontObject) => ({
        meta: {
          saveHistory: true
        },
        payload: {
          layerId,
          formatId,
          fontObject
        }
      })
    },

    pasteLayer: {
      reducer: (state, action) => {
        const deepCopy = (copy) => JSON.parse(JSON.stringify(copy));

        const oldLayer = action.payload.copied.layer;
        const newLayer = deepCopy(oldLayer);

        delete newLayer.children;

        const oldLayerID = action.payload.copied.layer.uuid;
        const newLayerID = action.payload.uuid;

        // Set new layer values
        newLayer.uuid = newLayerID;
        newLayer.id = newLayerID;
        newLayer.format.x += 20;
        newLayer.format.y += 20;

        const changedAnimations = {};

        // Handle Animations
        if (newLayer.animations) {
          for (const animation of newLayer.animations) {
            const newAnimationID = uuidv4();

            changedAnimations[animation.uuid] = {
              newAnimationID,
              oldAnimationID: animation.uuid,
              newAnimationTarget: newLayerID,
              oldAnimationTarget: animation.target
            };

            animation.uuid = newAnimationID;
            animation.target = newLayerID;
          }
        }

        // Check if we need to copy format settings
        for (const format of action.payload.copied.formats) {
          if (format.object_data.layers[oldLayerID]) {
            const oldSettings = format.object_data.layers[oldLayerID];
            const newSettings = deepCopy(oldSettings);

            if (newSettings.format) {
              newSettings.format.x += 20;
              newSettings.format.y += 20;
            }

            if (newSettings.animations) {
              if (Object.keys(changedAnimations).length > 0) {
                for (const [id, animation] of Object.entries(newSettings.animations)) {
                  const changed = changedAnimations[animation.uuid];

                  if (changed === undefined) continue;

                  const oldAnimation = animation;
                  const newAnimation = deepCopy(oldAnimation);

                  newAnimation.target = changed.newAnimationTarget;
                  newAnimation.uuid = changed.newAnimationID;

                  newSettings.animations[changed.newAnimationID] = newAnimation;
                  delete newSettings.animations[changed.oldAnimationID];
                }
              }
            }

            for (const stateFormat of state.formats) {
              if (stateFormat.uuid === format.uuid) {
                stateFormat.object_data.layers[newLayerID] = newSettings;
              }
            }
          }
        }

        // Handle renaming of the duplicated layer
        const nameMatch = newLayer.name.match(/ \(([0-9]*)\)$/);

        if (nameMatch) {
          newLayer.name = newLayer.name.replace(/\(([0-9]*)\)$/, `(${parseInt(nameMatch[1]) + 1})`);
        } else {
          newLayer.name += ' (1)';
        }

        // Handle children layers
        if (newLayer.layers && newLayer.layers.length > 0) {
          const newChildLayers = [];

          for (const layer of newLayer.layers) {
            const oldChildLayer = layer;
            const newChildLayer = deepCopy(oldChildLayer);
            delete newChildLayer.children;

            const oldChildLayerID = layer.uuid;
            const newChildLayerID = uuidv4();

            // Set new layer values
            newChildLayer.uuid = newChildLayerID;
            newChildLayer.id = newChildLayerID;

            const changedChildAnimations = {};

            // Handle Animations
            if (newChildLayer.animations) {
              for (const animation of newChildLayer.animations) {
                const newAnimationID = uuidv4();

                changedChildAnimations[animation.uuid] = {
                  newAnimationID,
                  oldAnimationID: animation.uuid,
                  newAnimationTarget: newChildLayerID,
                  oldAnimationTarget: animation.target
                };

                animation.uuid = newAnimationID;
                animation.target = newChildLayerID;
              }
            }

            // Check if we need to copy format settings
            for (const format of action.payload.copied.formats) {
              if (format.object_data.layers[oldChildLayerID]) {
                const oldChildSettings = format.object_data.layers[oldChildLayerID];
                const newChildSettings = deepCopy(oldChildSettings);

                if (newChildSettings.format) {
                  // newChildSettings.format.x += 20;
                  // newChildSettings.format.y += 20;
                }

                if (
                  newChildSettings.animations &&
                  Object.keys(newChildSettings.animations).length > 0
                ) {
                  if (Object.keys(changedChildAnimations).length > 0) {
                    for (const [id, animation] of Object.entries(newChildSettings.animations)) {
                      const changed = changedChildAnimations[animation.uuid];

                      if (changed === undefined) continue;

                      const oldAnimation = animation;
                      const newAnimation = deepCopy(oldAnimation);

                      newAnimation.target = changed.newAnimationTarget;
                      newAnimation.uuid = changed.newAnimationID;

                      newChildSettings.animations[changed.newAnimationID] = newAnimation;
                      delete newChildSettings.animations[changed.oldAnimationID];
                    }
                  }
                }

                for (const stateFormat of state.formats) {
                  if (stateFormat.uuid === format.uuid) {
                    stateFormat.object_data.layers[newChildLayerID] = newChildSettings;
                  }
                }
              }
            }

            // Handle renaming of the duplicated layer
            const nameMatchChild = newChildLayer.name.match(/ \(([0-9]*)\)$/);

            if (nameMatchChild) {
              newChildLayer.name = newChildLayer.name.replace(
                /\(([0-9]*)\)$/,
                `(${parseInt(nameMatchChild[1]) + 1})`
              );
            } else {
              newChildLayer.name += ' (1)';
            }

            newChildLayers.push(newChildLayer);
          }

          newLayer.layers = newChildLayers;
        }

        state.object_data.layers = [...[newLayer], ...state.object_data.layers];
      },
      prepare: (copied, format = null) => ({
        meta: {
          saveHistory: true
        },
        payload: {
          uuid: uuidv4(),
          copied
        }
      })
    },
    deleteLayer: {
      reducer: (state, action) => {
        // Delete layer in Tempate
        const deletedLayers = [];

        const spliceLayer = (layerId, layers) => {
          for (const index in layers) {
            if (layers[index].uuid === layerId) {
              const deleted = layers.splice(index, 1)[0];
              deletedLayers.push(deleted);
            } else {
              spliceLayer(layerId, layers[index].layers);
            }
          }
        };

        spliceLayer(action.payload, state.object_data.layers);

        // Delete layers in formats
        const deleteFormatLayers = (layers) => {
          if (layers) {
            for (const layer of layers) {
              for (const format of state.formats) {
                delete format.object_data.layers[layer.uuid];
              }

              if (layer.layers) {
                deleteFormatLayers(layer.layers);
              }
            }
          }
        };

        deleteFormatLayers(deletedLayers);

        for (const format of state.formats) {
          format.object_data.fonts = updateFontsArray(
            format.object_data.fonts,
            state.object_data.layers,
            format
          );
          format.object_data.images = updateImagesArray(
            format.object_data.images,
            state.object_data.layers,
            format
          );
          format.object_data.videos = updateVideosArray(
            format.object_data.videos,
            state.object_data.layers,
            format
          );
        }
      },
      prepare: (layerId) => ({
        meta: {
          saveHistory: true
        },
        payload: layerId
      })
    },
    updateLayerPosition: {
      reducer: (state, action) => {
        const { formatId, layerId, newPosition } = action.payload;
        function addFormatPosition(layerId, format) {
          const formatPosition = deepCopyObject({ ...format });

          for (const format of state.formats) {
            if (format.id === formatId) {
              let layer = format.object_data.layers[layerId];

              if (layer === undefined) {
                layer = {};
                format.object_data.layers[layerId] = layer;
              }

              if (!('format' in layer)) {
                layer.format = {};
              }

              layer.format = {
                ...layer.format,
                ...formatPosition
              };

              break;
            }
          }
        }

        for (const layer of state.object_data.layers) {
          if (layer.uuid === layerId) {
            if (!('format' in layer)) {
              layer.format = {};
            }
            addFormatPosition(layer.uuid, newPosition);

            layer.format = {
              ...layer.format,
              ...newPosition
            };
          } else if (layer.layers && layer.layers.length > 0) {
            for (const childLayer of layer.layers) {
              if (childLayer.uuid === layerId) {
                Object.keys(newPosition).forEach((el) => {
                  newPosition[el] = parseInt(newPosition[el]);
                });

                if (!('format' in childLayer)) {
                  childLayer.format = {};
                }

                addFormatPosition(childLayer.uuid, newPosition);

                childLayer.format = {
                  ...childLayer.format,
                  ...newPosition
                };
              }
            }
          }
        }
        state = recalculateGroups(state, formatId, newPosition);
      },
      prepare: (formatId, layerId, position) => ({
        meta: {
          saveHistory: true
        },
        payload: {
          formatId,
          layerId,
          newPosition: position
        }
      })
    },
    updateLayerEditorSettings: {
      reducer: (state, action) => {
        const { newSettings, layerId, formatId } = action.payload;
        for (const format of state.formats) {
          if (format.id === formatId) {
            let layer = format.object_data.layers[layerId];
            if (layer === undefined) {
              layer = {};
              format.object_data.layers[layerId] = layer;
            }
            if (!('editor_settings' in layer)) {
              layer.editor_settings = {};
            }
            layer.editor_settings = {
              ...layer.editor_settings,
              ...newSettings
            };
            break;
          }
        }
      },
      prepare: (formatId, layerId, settings) => ({
        meta: {
          saveHistory: true
        },
        payload: {
          formatId,
          layerId,
          newSettings: settings
        }
      })
    },
    updateLayerSettings: {
      reducer: (state, action) => {
        const newSettings = action.payload.settings;
        function setSettingsTemplateLevel(layers) {
          for (const layer of layers) {
            if (layer.uuid === action.payload.layerId) {
              if (!('settings' in layer)) {
                layer.settings = {};
              }
              layer.settings = {
                ...layer.settings,
                ...newSettings
              };
              break;
            }

            if ('layers' in layer && layer.layers.length > 0) {
              setSettingsTemplateLevel(layer.layers);
            }
          }
        }

        setSettingsTemplateLevel(state.object_data.layers);

        if ('font' in newSettings) {
          delete newSettings.font; // the font setting is only on template level.
        }
        for (const format of state.formats) {
          if (format.id === action.payload.formatId) {
            let layer = format.object_data.layers[action.payload.layerId];
            if (layer === undefined) {
              layer = {};
              format.object_data.layers[action.payload.layerId] = layer;
            }
            if (!('settings' in layer)) {
              layer.settings = {};
            }
            layer.settings = {
              ...layer.settings,
              ...newSettings
            };
            break;
          }
        }
      },
      prepare: (formatId, layerId, settings) => ({
        meta: {
          saveHistory: true
        },
        payload: {
          formatId,
          layerId,
          settings
        }
      })
    },
    updateLayerName: {
      reducer: (state, action) => {
        const { layerId, name } = action.payload;
        const updateLayerName = (layerId, name, layers) => {
          for (const layer of layers) {
            if (layer.uuid === layerId) {
              layer.name = name;
              break;
            }
            if ('layers' in layer && layer.layers.length > 0) {
              updateLayerName(layerId, name, layer.layers);
            }
          }
        };
        updateLayerName(layerId, name, state.object_data.layers);
      },
      prepare: (layerId, newName) => ({
        meta: {
          saveHistory: true
        },
        payload: {
          layerId,
          name: newName
        }
      })
    },
    updateLayerImage: {
      reducer: (state, action) => {
        const { layerId, newImage, formatTargetId } = action.payload;
        for (const format of state.formats) {
          if (formatTargetId === format.id) {
            let isNewImage = true;
            if (!format.object_data.images) format.object_data.images = [];
            for (const image of format.object_data.images) {
              if (image.uuid === newImage.uuid) {
                isNewImage = false;
                break;
              }
            }
            if (isNewImage) {
              format.object_data.images.push(newImage);
            }
            break;
          }
        }
        const changeLayerImage = (layers) => {
          for (const layer of layers) {
            if (layer.uuid === layerId) {
              layer.settings.source = newImage.uuid;
              for (const format of state.formats) {
                if (formatTargetId === format.id) {
                  if (format.object_data.layers[layer.uuid].settings.imageState) {
                    // update the state of the image to adjust the size
                    format.object_data.layers[layer.uuid].settings.imageState = updateImageState(
                      format.object_data.layers[layer.uuid].settings.imageState,
                      newImage
                    );
                  }
                  format.object_data.layers[layer.uuid].settings.source = newImage.uuid;

                  break;
                }
              }
              break;
            }
            if (layer.layers) {
              changeLayerImage(layer.layers);
            }
          }
        };

        changeLayerImage(state.object_data.layers);

        for (const format of state.formats) {
          if (format.id === formatTargetId) {
            let layer = format.object_data.layers[layerId];
            if (layer === undefined) {
              layer = {};
              format.object_data.layers[layerId] = layer;
            }
            if (!('settings' in layer)) {
              layer.settings = {};
            }
            if (layer.settings.imageState) {
              layer.settings.imageState = updateImageState(layer.settings.imageState, newImage);
            }
            layer.settings.source = newImage.uuid;
            break;
          }
        }
        for (const format of state.formats) {
          if (formatTargetId === format.id) {
            format.object_data.images = updateImagesArray(
              format.object_data.images,
              state.object_data.layers,
              format
            );
            break;
          }
        }
      },
      prepare: (layerId, imageObj, formatTargetId) => {
        const newImage = {
          uuid: imageObj.uuid,
          source: imageObj.url,
          width: imageObj.width,
          height: imageObj.height
        };
        return {
          meta: {
            saveHistory: true
          },
          payload: {
            layerId,
            newImage,
            formatTargetId
          }
        };
      }
    },
    updateLayerImageScale: {
      reducer: (state, action) => {
        const { layerId, formatId, imageState } = action.payload;

        function updateLayer(layer) {
          if (!('settings' in layer)) layer.settings = {};
          if (!('imageState' in layer.settings)) layer.settings.imageState = {};

          layer.settings.imageState = imageState;
        }

        // Set on current format
        state.formats.forEach((format) => {
          if (format.id === formatId) {
            if (!format.object_data.layers[layerId]) format.object_data.layers[layerId] = {};
            const layer = format.object_data.layers[layerId];
            updateLayer(layer);
          }
        });

        // on template
        state.object_data.layers.forEach((layer) => {
          if (layer.id === layerId) {
            updateLayer(layer);
          }
        });
      },
      prepare: (layerId, formatId, imageState, resize) => ({
        meta: {
          saveHistory: true
        },
        payload: {
          layerId,
          formatId,
          imageState,
          resize
        }
      })
    },
    updateLayerImageState: {
      reducer: (state, action) => {
        const { layerId, formatId, imageState, resizeLayer = true } = action.payload;

        function updateLayer(layer) {
          if (!('settings' in layer)) layer.settings = {};
          if (!('imageState' in layer.settings)) layer.settings.imageState = {};

          layer.settings.imageState = imageState;
        }

        // Set on current format
        state.formats.forEach((format) => {
          if (format.id === formatId) {
            if (!format.object_data.layers[layerId]) format.object_data.layers[layerId] = {};
            const layer = format.object_data.layers[layerId];
            updateLayer(layer);
          }
        });

        // on template
        state.object_data.layers.forEach((layer) => {
          if (layer.id === layerId) {
            updateLayer(layer);
          }
          if ('layers' in layer && layer.layers.length > 0) {
            for (const childLayer of layer.layers) {
              if (childLayer.id === layerId) {
                updateLayer(childLayer);
              }
            }
          }
        });
      },
      prepare: (layerId, formatId, imageState, resize) => ({
        meta: {
          saveHistory: true
        },
        payload: {
          layerId,
          formatId,
          imageState,
          resize
        }
      })
    },
    applyTextToAllFormats: {
      reducer: (state, action) => {
        const { layerId, formatId, text } = action.payload;
        // change text on formats
        for (const format of state.formats) {
          if (formatId !== format.id) {
            const layer = format.object_data.layers[layerId];
            if (layer.settings) {
              layer.settings.text = text;
            } else {
              layer.settings = {};
              layer.settings.text = text;
            }
          }
        }

        // change text on layer on template
        const templateLayer = findLayer(layerId, state.object_data.layers);
        templateLayer.settings.text = text;
      },
      prepare: (layerId, formatId, text) => ({
        meta: {
          saveHistory: true
        },
        payload: {
          layerId,
          formatId,
          text
        }
      })
    },
    applyImageToAllFormats: {
      reducer: (state, action) => {
        // we need the imageObj to get the original size
        const { layerId, formatId, imageObj } = action.payload;
        const sourceFormat = state.formats.find((f) => f.id === formatId);
        const sourceLayer = sourceFormat.object_data.layers[layerId];
        const sourceImage = sourceFormat.object_data.images.find(
          (i) => i.uuid === sourceLayer.settings.source
        );

        if (!sourceImage) {
          throw new Error('Error in applyImageToAllFormats - no sourceImage found');
        }
        for (const format of state.formats) {
          if (formatId !== format.id) {
            let isNewImage = true;
            if (!format.object_data.images) format.object_data.images = [];
            for (const image of format.object_data.images) {
              if (image.uuid === sourceImage.uuid) {
                isNewImage = false;
                break;
              }
            }
            if (isNewImage) {
              format.object_data.images.push(sourceImage);
            }
          }
          const layer = format.object_data.layers[layerId];
          if (!layer.settings) layer.settings = {};
          // calculate the size of the image we want to crop for format
          if (layer.settings.imageState) {
            layer.settings.imageState = updateImageState(layer.settings.imageState, imageObj);
          }

          layer.settings.source = sourceLayer.settings.source;
          format.object_data.images = updateImagesArray(
            format.object_data.images,
            state.object_data.layers,
            format
          );
        }
      },
      prepare: (layerId, formatId, imageObj) => {
        return {
          meta: {
            saveHistory: true
          },
          payload: {
            layerId,
            formatId,
            imageObj
          }
        };
      }
    },
    applyVideoToAllFormats: {
      reducer: (state, action) => {
        const { layerId, formatId } = action.payload;
        const sourceFormat = state.formats.find((f) => f.id === formatId);
        const sourceLayer = sourceFormat.object_data.layers[layerId];
        const sourceVideo = sourceFormat.object_data.videos.find(
          (v) => v.uuid === sourceLayer.settings.source
        );

        for (const format of state.formats) {
          if (formatId !== format.id) {
            let isNewVideo = true;
            if (!format.object_data.videos) format.object_data.videos = [];
            for (const image of format.object_data.videos) {
              if (image.uuid === sourceVideo.uuid) {
                isNewVideo = false;
                break;
              }
            }
            if (isNewVideo) {
              format.object_data.videos.push(sourceVideo);
            }
          }
          const layer = format.object_data.layers[layerId];
          if (!layer.settings) layer.settings = {};
          layer.settings.source = sourceLayer.settings.source;
          format.object_data.videos = updateVideosArray(
            format.object_data.videos,
            state.object_data.layers,
            format
          );
        }
      },
      prepare: (layerId, formatId) => {
        return {
          meta: {
            saveHistory: true
          },
          payload: {
            layerId,
            formatId
          }
        };
      }
    },
    updateLayerVideo: {
      reducer: (state, action) => {
        const { layerId, newVideo, formatTargetId } = action.payload;
        for (const format of state.formats) {
          if (formatTargetId === format.id) {
            let isNewVideo = true;
            if (!format.object_data.videos) format.object_data.videos = [];
            for (const video of format.object_data.videos) {
              if (video.uuid === newVideo.uuid) {
                isNewVideo = false;
                break;
              }
            }
            if (isNewVideo) {
              format.object_data.videos.push(newVideo);
            }
          }
        }

        const changeLayerVideo = (layers) => {
          for (const layer of layers) {
            if (layer.uuid === layerId) {
              if (!('settings' in layer)) {
                layer.settings = {};
              }
              layer.settings.source = newVideo.uuid;
              if ('animations' in layer && layer.animations.length > 0) {
                layer.animations.forEach((animation) => {
                  if (animation.type === 'playVideo') {
                    const newDuration = parseFloat(
                      (Math.ceil((newVideo.duration + 0.1) * 100) / 100).toFixed(2)
                    );
                    animation.duration = newDuration;
                    animation.maxDuration = newDuration;
                  }
                });
              }
              for (const format of state.formats) {
                if (formatTargetId === format.id) {
                  const formatLayer = format.object_data.layers[layer.uuid];
                  if (!('settings' in formatLayer)) {
                    formatLayer.settings = {};
                  }
                  formatLayer.settings.source = newVideo.uuid;
                  if ('animations' in formatLayer && formatLayer.animations.length > 0) {
                    formatLayer.animations.forEach((animation) => {
                      if (animation.type === 'playVideo') {
                        const newDuration = parseFloat(
                          (Math.ceil((newVideo.duration + 0.1) * 100) / 100).toFixed(2)
                        );
                        animation.duration = newDuration;
                        animation.maxDuration = newDuration;
                      }
                    });
                  }
                  break;
                }
              }
              break;
            }
            if (layer.layers) {
              changeLayerVideo(layer.layers);
            }
          }
        };

        changeLayerVideo(state.object_data.layers);

        for (const format of state.formats) {
          if (formatTargetId === format.id) {
            format.object_data.videos = updateVideosArray(
              format.object_data.videos,
              state.object_data.layers,
              format
            );
            break;
          }
        }
      },
      prepare: (layerId, videoObj, formatTargetId) => {
        const newVideo = {
          uuid: videoObj.uuid,
          source: videoObj.url,
          duration: videoObj.duration
        };
        return {
          meta: {
            saveHistory: true
          },
          payload: {
            layerId,
            newVideo,
            formatTargetId
          }
        };
      }
    },

    // history
    resetUndoHistory(state, action) {
      const historyObject = makeHistoryObject(state);
      state._history = {
        ...initialState._history,
        current: historyObject
      };
    },

    undo(state, action) {
      if (state._history.undo.length === 0) return state;

      const newCurrentState = state._history.undo.pop();
      for (const format of state.formats) {
        format.object_data = newCurrentState.formats[format.id];
      }
      state.object_data = newCurrentState.object_data;
      state._history = {
        undo: [...state._history.undo],
        current: newCurrentState,
        redo: [...state._history.redo, state._history.current],
        lastestActionType: false,
        latestActionTime: 0
      };
    },

    redo(state, action) {
      if (state._history.redo.length === 0) return state;

      const newCurrentState = state._history.redo.pop();

      for (const format of state.formats) {
        format.object_data = newCurrentState.formats[format.id];
      }
      state.object_data = newCurrentState.object_data;
      state._history = {
        undo: [...state._history.undo, state._history.current],
        current: newCurrentState,
        redo: [...state._history.redo],
        lastestActionType: false,
        latestActionTime: 0
      };
    },

    // dco
    updateLayerDCO: {
      reducer: (state, action) => {
        const { layers } = state.object_data;
        const { layerId, settings } = action.payload;

        function checkLayers(layers) {
          for (const layer of layers) {
            if (layer.uuid === layerId) {
              if (layer.dynamic !== undefined) {
                layer.dynamic = settings;
              }
            }

            if ('layers' in layer && layer.layers.length > 0) {
              checkLayers(layer.layers);
            }
          }
        }

        checkLayers(layers);
      },
      prepare: (layerId, settings) => ({
        meta: {
          saveHistory: true
        },
        payload: {
          layerId,
          settings
        }
      })
    },
    setTemplateDCO: {
      reducer: (state, action) => {
        state.object_data.dynamic = action.payload;
      },
      prepare: (dynamic) => ({
        meta: {
          saveHistory: false
        },
        payload: dynamic
      })
    },
    // animation
    addAnimation: {
      reducer: (state, action) => {
        const layers = [...state.object_data.layers];

        // Make this recursive if we have groups in groups
        function setSettings(layers) {
          for (const layer of layers) {
            if (layer.uuid === action.payload.layerId) {
              if (!('animations' in layer)) {
                layer.animations = [];
              }

              layer.animations.push(action.payload.animation);

              if (!('settings' in state.object_data)) {
                state.object_data.settings = {};
              }
              if (!('duration' in state.object_data.settings)) {
                state.object_data.settings.duration = 0;
              }
              const updatedDuration = updateFormatDuration(
                layer.animations,
                state.object_data.settings.duration
              );

              state.object_data.settings.duration = updatedDuration / 100;
              break;
            }

            if ('layers' in layer && layer.layers.length > 0) {
              setSettings(layer.layers);
            }
          }
        }

        setSettings(layers);
        state.object_data = {
          ...state.object_data,
          layers
        };
      },
      prepare: (formatId, layerId, animationObject) => ({
        meta: {
          saveHistory: true
        },
        payload: {
          formatId,
          layerId,
          animation: animationObject
        }
      })
    },
    deleteAnimation: {
      reducer: (state, action) => {
        const { layers } = state.object_data;

        function setSettings(layers) {
          for (const layer of layers) {
            if (layer.uuid === action.payload.layerId) {
              if (!('animations' in layer)) {
                layer.animations = [];
              }
              for (const index in layer.animations) {
                if (action.payload.animationId === layer.animations[index].uuid) {
                  layer.animations.splice(index, 1);
                  break;
                }
              }
            }

            if ('layers' in layer && layer.layers.length > 0) {
              setSettings(layer.layers);
            }
          }
        }

        setSettings(layers);

        const { formats } = state;
        for (const format of formats) {
          let layer = format.object_data.layers[action.payload.layerId];

          if (layer === undefined) {
            layer = {};
          }

          if (!('animations' in layer)) {
            layer.animations = {};
          }

          if (layer.animations[action.payload.layerId]) {
            delete layer.animations[action.payload.layerId];
            break;
          }
        }
      },
      prepare: (formatId, layerId, animationId) => ({
        meta: {
          saveHistory: true
        },
        payload: {
          formatId,
          layerId,
          animationId
        }
      })
    },
    updateAnimation: {
      reducer: (state, action) => {
        const updateAnimation = action.payload.animation;
        const { layers } = state.object_data;

        function setSettings(layers) {
          for (const layer of layers) {
            if (layer.uuid === action.payload.layerId) {
              if (!('animations' in layer)) {
                layer.animations = [];
                layer.animations.push(updateAnimation);
              } else {
                for (const i in layer.animations) {
                  if (layer.animations[i].uuid === updateAnimation.uuid) {
                    layer.animations[i] = updateAnimation;
                    break;
                  }
                }
              }

              if (!('settings' in state.object_data)) {
                state.object_data.settings = {};
              }
              if (!('duration' in state.object_data.settings)) {
                state.object_data.settings.duration = 0;
              }
              const updatedDuration = updateFormatDuration(
                layer.animations,
                state.object_data.settings.duration
              );

              state.object_data.settings.duration = updatedDuration / 100;
              break;
            }
            if ('layers' in layer && layer.layers.length > 0) {
              setSettings(layer.layers);
            }
          }
        }

        setSettings(layers);

        // Make sure we only put needed keys into format.
        const updateAnimationTest = deepCopyObject(updateAnimation);

        if (
          updateAnimation.type in
          ['opacity', 'rotate', 'skewX', 'skewY', 'flipX', 'flipY', 'radius', 'background']
        ) {
          delete updateAnimationTest.settings;
        }

        delete updateAnimationTest.direction;
        delete updateAnimationTest.ease;
        delete updateAnimationTest.easeType;
        delete updateAnimationTest.only_on_repeat;
        delete updateAnimationTest.type;
        delete updateAnimationTest.duration;
        delete updateAnimationTest.time;

        const { formats } = state;
        for (const format of formats) {
          if (format.id === action.payload.formatId) {
            let layer = format.object_data.layers[action.payload.layerId];

            if (layer === undefined) {
              layer = {};
              format.object_data.layers[action.payload.layerId] = layer;
            }

            if (!('animations' in layer)) {
              layer.animations = {};
            }

            layer.animations[updateAnimationTest.uuid] = updateAnimationTest;
            break;
          }
        }
      },
      prepare: (formatId, layerId, animationObject) => {
        if (animationObject?.type === 'playVideo') {
          const copyAnimationObject = { ...animationObject };
          animationObject = {
            type: 'playVideo'
          };
          if ('uuid' in copyAnimationObject) animationObject.uuid = copyAnimationObject.uuid;
          if ('time' in copyAnimationObject) animationObject.time = copyAnimationObject.time;
          if ('duration' in copyAnimationObject)
            animationObject.duration = copyAnimationObject.duration;
          if ('maxDuration' in copyAnimationObject)
            animationObject.maxDuration = copyAnimationObject.maxDuration;
          if ('only_on_repeat' in copyAnimationObject)
            animationObject.only_on_repeat = copyAnimationObject.only_on_repeat;
          if ('target' in copyAnimationObject) animationObject.target = copyAnimationObject.target;
        } else if (!('direction' in animationObject)) animationObject.direction = 'to';

        return {
          meta: {
            saveHistory: true
          },
          payload: {
            formatId,
            layerId,
            animation: animationObject
          }
        };
      }
    },

    updateLayerFormat: {
      reducer: (state, action) => {
        const newFormat = action.payload.format;

        for (const attr in newFormat) {
          if (attr === 'line_height') {
            newFormat[attr] = Number.isNaN(newFormat[attr]) ? 'inherit' : newFormat[attr];
          }
        }

        const templateLayer = findLayer(action.payload.layerId, state.object_data.layers);

        if (!('format' in templateLayer)) {
          templateLayer.format = {};
        }

        templateLayer.format = {
          ...templateLayer.format,
          ...deepCopyObject(newFormat)
        };

        const { formats } = state;
        for (const format of formats) {
          if (format.id === action.payload.formatId) {
            let layer = format.object_data.layers[action.payload.layerId];

            if (layer === undefined) {
              layer = {};
              format.object_data.layers[action.payload.layerId] = layer;
            }

            if (!('format' in layer)) {
              layer.format = {};
            }

            const updateAttributes = function (attributes, allowObject, data = {}) {
              for (const attributeName in attributes) {
                if (typeof allowObject[attributeName] === 'object') {
                  data[attributeName] = updateAttributes(
                    attributes[attributeName],
                    allowObject[attributeName],
                    data[attributeName]
                  );
                } else if (allowObject[attributeName]) {
                  data[attributeName] = attributes[attributeName];
                } else if (!allowObject[attributeName]) {
                  delete data[attributeName];
                  data[attributeName] = attributes[attributeName];
                }
              }
              return data;
            };
            layer.format = updateAttributes(
              deepCopyObject(newFormat),
              allowedOnFormat.format,
              layer.format
            );
          }
        }

        state = recalculateGroups(state, action.payload.formatId, deepCopyObject(newFormat));
      },
      prepare: (formatId, layerId, format) => ({
        meta: {
          saveHistory: true
        },
        payload: {
          formatId,
          layerId,
          format
        }
      })
    },

    updateFormatLayerSettings: {
      reducer: (state, action) => {
        // TODO: Decide wether to always use the same image, videos, clicktag (when relevant) across formats etc.
        for (const format of state.formats) {
          if (format.id === action.payload.formatId) {
            let layer = format.object_data.layers[action.payload.layerId];
            if (layer === undefined) {
              layer = {};
              format.object_data.layers[action.payload.layerId] = layer;
            }
            if (!('settings' in layer)) {
              layer.settings = {};
            }

            layer.settings = {
              ...layer.settings,
              ...action.payload.settings
            };
            break;
          }
        }
      },
      prepare: (formatId, layerId, settings) => ({
        meta: {
          saveHistory: true
        },
        payload: {
          formatId,
          layerId,
          settings
        }
      })
    },
    addContentSpecificClicktag: {
      reducer: (state, action) => {
        if (action.payload.newClicktagObj) {
          const contentSpecificClicktag = action.payload.newClicktagObj;
          const selectedFormat = state.formats.find(
            (format) => format.id === action.payload.selectedFormatId
          );

          const isLinked = state.object_data?.settings?.linked_clicktags ?? true;
          if (isLinked && !('linked_clicktags' in state.object_data.settings)) {
            state.object_data.settings.linked_clicktags = true;
          }

          const clicktags =
            (isLinked ? state.object_data?.clicktags : selectedFormat.object_data?.clicktags) ?? [];
          let isNewClicktag = true;
          for (const clicktag of clicktags) {
            if (clicktag.uuid === contentSpecificClicktag.uuid) {
              isNewClicktag = false;
              break;
            }
          }
          if (isNewClicktag) {
            clicktags.unshift(contentSpecificClicktag);
          }
          if (isLinked) {
            for (const format of state.formats) {
              // We're deleting all clicktags on the formats if they are linked.
              delete format.object_data.clicktags;
            }
            state.object_data.clicktags = clicktags;
          } else {
            selectedFormat.object_data.clicktags = clicktags;
          }
        }
      },
      prepare: (newClicktagObj, selectedFormatId) => ({
        meta: {
          saveHistory: true
        },
        payload: {
          newClicktagObj,
          selectedFormatId
        }
      })
    },
    updateContentSpecificClicktag: {
      reducer: (state, action) => {
        const { clicktagId, data } = action.payload;
        const selectedFormat = state.formats.find(
          (format) => format.id === action.payload.selectedFormatId
        );
        const isLinked = state.object_data?.settings?.linked_clicktags ?? true;
        const clicktags =
          (isLinked ? state.object_data?.clicktags : selectedFormat.object_data?.clicktags) ?? [];
        const clicktagIndex = clicktags.findIndex((clicktag) => clicktag.uuid === clicktagId);
        clicktags.splice(clicktagIndex, 1, data);
      },
      prepare: (clicktagId, data, selectedFormatId) => ({
        meta: {
          saveHistory: true
        },
        payload: {
          clicktagId,
          data,
          selectedFormatId
        }
      })
    },
    deleteContentSpecificClicktag: {
      reducer: (state, action) => {
        const { clicktagId } = action.payload;
        const selectedFormat = state.formats.find(
          (format) => format.id === action.payload.selectedFormatId
        );
        const isLinked = state.object_data?.settings?.linked_clicktags ?? true;
        const clicktags =
          (isLinked ? state.object_data?.clicktags : selectedFormat.object_data?.clicktags) ?? [];
        const clicktagIndex = clicktags.findIndex((clicktag) => clicktag.uuid === clicktagId);
        // remove clicktag from template
        clicktags.splice(clicktagIndex, 1);
        // remove clicktag from layers
        if ('layers' in state.object_data && state.object_data.layers.length > 0) {
          removeClicktagFromLayers(
            clicktagId,
            state.object_data.layers,
            selectedFormat.object_data.layers,
            isLinked
          );
        }
      },
      prepare: (clicktagId, selectedFormatId) => ({
        meta: {
          saveHistory: true
        },
        payload: {
          clicktagId,
          selectedFormatId
        }
      })
    },
    updateContentSpecificClicktagOnLayer: {
      reducer: (state, action) => {
        const { layerId, clicktagId, updateType } = action.payload;
        const selectedFormat = state.formats.find(
          (format) => format.id === action.payload.selectedFormatId
        );
        updateClicktagOnlayer(
          layerId,
          clicktagId,
          updateType,
          state.object_data.layers,
          selectedFormat.object_data.layers,
          state.settings?.linked_clicktags ?? true
        );
      },
      prepare: (layerId, clicktagId, updateType, selectedFormatId) => ({
        meta: {
          saveHistory: true
        },
        payload: {
          layerId,
          clicktagId,
          updateType,
          selectedFormatId
        }
      })
    },
    setCustomGuides(state, action) {
      const { formatId, customGuides } = action.payload;
      const indexOfFormat = state.formats.findIndex((format) => format.id === formatId);

      if (customGuides && customGuides.length > 0) {
        state.formats[indexOfFormat].guidelines.customGuides = customGuides;
      } else {
        state.formats[indexOfFormat].guidelines.customGuides = [];
      }
    },
    addCustomGuide(state, action) {
      const { formatId, customGuide } = action.payload;

      const indexOfFormat = state.formats.findIndex((format) => format.id === formatId);

      if (
        !state.formats[indexOfFormat].guidelines ||
        !state.formats[indexOfFormat].guidelines.customGuides
      ) {
        state.formats[indexOfFormat].guidelines = {};
        state.formats[indexOfFormat].guidelines.customGuides = [];
      }

      state.formats[indexOfFormat].guidelines.customGuides = [
        ...state.formats[indexOfFormat].guidelines.customGuides,
        customGuide
      ];
    },
    updateCustomGuide(state, action) {
      const { formatId, customGuide } = action.payload;

      const indexOfFormat = state.formats.findIndex((format) => format.id === formatId);
      const customGuideIndex = state.formats[indexOfFormat].guidelines.customGuides.findIndex(
        (guide) => guide.uuid === customGuide.uuid
      );

      state.formats[indexOfFormat].guidelines.customGuides[customGuideIndex] = customGuide;
    },
    removeCustomGuide(state, action) {
      const { formatId, customGuideId } = action.payload;

      const indexOfFormat = state.formats.findIndex((format) => format.id === formatId);
      const customGuideIndex = state.formats[indexOfFormat].guidelines.customGuides.findIndex(
        (customGuide) => customGuide.uuid === customGuideId
      );

      state.formats[indexOfFormat].guidelines.customGuides.splice(customGuideIndex, 1);
    },
    setPublic(state, action) {
      state.public = action.payload;
    },
    setTags(state, action) {
      state.tags = action.payload;
    },
    addTag(state, action) {
      state.tags = [...state.tags, action.payload];
    },
    removeTag(state, action) {
      const index = state.tags.findIndex((tagId) => tagId === action.payload);
      state.tags.splice(index, 1);
    }
  }
});
function updateClicktagOnlayer(
  layerId,
  clicktagId,
  updateType,
  templateLayers,
  formatLayers,
  isLinked = true
) {
  for (const templateLayer of templateLayers) {
    if (templateLayer.uuid === layerId) {
      const layer =
        !isLinked && formatLayers[templateLayer.uuid]
          ? formatLayers[templateLayer.uuid]
          : templateLayer;
      if (updateType === 'addClicktag') {
        if (!('clicktag' in layer.settings)) {
          layer.settings.clicktag = [];
        }
        layer.settings.clicktag.push(clicktagId);
      }
      if (updateType === 'removeClicktag' && layer.settings.clicktag.includes(clicktagId)) {
        const clicktagIdIndex = layer.settings.clicktag.findIndex((item) => item === clicktagId);
        layer.settings.clicktag.splice(clicktagIdIndex, 1);
      }
    }
    if (templateLayer.type === 'group' && templateLayer.layers) {
      updateClicktagOnlayer(
        layerId,
        clicktagId,
        updateType,
        templateLayer.layers,
        formatLayers,
        isLinked
      );
    }
  }
}
function removeClicktagFromLayers(clicktagId, templateLayers, formatLayers, isLinked = true) {
  for (const templateLayer of templateLayers) {
    const layer =
      !isLinked && formatLayers[templateLayer.uuid]
        ? formatLayers[templateLayer.uuid]
        : templateLayer;
    if (!('clicktag' in layer?.settings)) {
      return;
    }
    if (layer.settings.clicktag.length > 0 && layer.settings.clicktag.includes(clicktagId)) {
      const clicktagIdIndex = layer.settings.clicktag.findIndex((item) => item.uuid === clicktagId);
      layer.settings.clicktag.splice(clicktagIdIndex, 1);
    }
    if (templateLayer.layers?.length > 0 && templateLayer.type === 'group') {
      removeClicktagFromLayers(clicktagId, templateLayer.layers, formatLayers, isLinked);
    }
  }
}

function updateFormatDuration(animations, duration) {
  const animationsTimes = getAnimationEndTimes(animations);
  const formatDuration = duration;

  const updatedDuration =
    formatDuration && Math.max(...animationsTimes) > formatDuration * 100
      ? Math.max(...animationsTimes)
      : formatDuration && Math.max(...animationsTimes) < formatDuration * 100
      ? formatDuration * 100
      : !formatDuration
      ? Math.max(...animationsTimes)
      : Math.max(...animationsTimes) === formatDuration * 100
      ? formatDuration * 100
      : 0;
  return updatedDuration;
}

function updateEntriesList(layerType, settingAttribute, entries, templateLayers, format) {
  if (!entries) return [];

  const getUsedIds = (layers) => {
    let usedEntries = [];
    if (layers !== undefined) {
      for (const layer of layers) {
        if (layer.type === 'group' && layer.layers) {
          usedEntries = usedEntries.concat(getUsedIds(layer.layers));
        }
        if (layer.type === layerType) {
          const formatLayerSettings =
            layer.id in format.object_data.layers &&
            'settings' in format.object_data.layers[layer.id]
              ? format.object_data.layers[layer.id].settings
              : {};
          const templateLayerSettings = 'settings' in layer ? layer.settings : {};
          const layerSettings = {
            ...templateLayerSettings,
            ...formatLayerSettings
          };
          usedEntries.push(layerSettings[settingAttribute]);
        }
      }
    }
    return usedEntries;
  };
  const usedEntries = getUsedIds(templateLayers);
  return entries.filter((f) => usedEntries.includes(f.uuid));
}

function updateFontsArray(fonts, layers, format) {
  return updateEntriesList('text', 'font', fonts, layers, format);
}

function updateImagesArray(images, layers, format) {
  return updateEntriesList('image', 'source', images, layers, format);
}

function updateVideosArray(videos, layers, format) {
  return updateEntriesList('video', 'source', videos, layers, format);
}

function makeHistoryObject(state) {
  const history = {};
  const formats = {};

  for (const format of state.formats) {
    // formats[format.id] = deepCopyObject(format.object_data);
    formats[format.id] = format.object_data;
  }

  // history.object_data = deepCopyObject(state.object_data);
  history.object_data = state.object_data;
  history.formats = formats;
  return history;
}

function deepCopyObject(obj) {
  return JSON.parse(JSON.stringify(obj));
}

// when removing layer from parent we have to
// recalculate the position relative to canvas
function getPositionOnCanvas(layer, layers) {
  let { x } = layer.format;
  let { y } = layer.format;

  const path = getPathById(layer.uuid, layers);

  // if group, add position to group
  if (path.length === 2) {
    x += parseInt(layers[path[0]].format.x);
    y += parseInt(layers[path[0]].format.y);
  }
  return { x, y };
}

function checkForMissingSettings(state, formatId = null, layerId = null) {
  // For each format.
  for (const format of state.formats) {
    // If the format is the selected one.
    if (format.id === formatId || formatId === null) {
      // For each layer in the template.
      const setMissingValues = (layers) => {
        for (const templateLayer of layers) {
          if (templateLayer.id === layerId || layerId === null) {
            const layerId = templateLayer.uuid;

            if (!(layerId in format.object_data.layers)) {
              format.object_data.layers[layerId] = {};
            }

            const formatLayer = format.object_data.layers[layerId];

            // Check for each format if we need to set any unset values
            const updateAttributes = function (attributes, allowObject, data = {}) {
              for (const attributeName in attributes) {
                if (typeof allowObject[attributeName] === 'object') {
                  data[attributeName] = updateAttributes(
                    attributes[attributeName],
                    allowObject[attributeName],
                    data[attributeName]
                  );
                } else if (allowObject[attributeName] && !(attributeName in data)) {
                  data[attributeName] = attributes[attributeName];
                } else if (!allowObject[attributeName]) {
                  delete data[attributeName];
                }
              }
              return data;
            };

            // Update format
            if (!('format' in formatLayer)) {
              formatLayer.format = {};
            }

            formatLayer.format = updateAttributes(
              templateLayer.format,
              allowedOnFormat.format,
              formatLayer.format
            );

            // Update settings
            if (!('settings' in formatLayer)) {
              formatLayer.settings = {};
            }

            formatLayer.settings = updateAttributes(
              templateLayer.settings,
              allowedOnFormat.settings,
              formatLayer.settings
            );
            if ('layers' in templateLayer) {
              setMissingValues(templateLayer.layers);
            }
          }
        }
      };

      if ('layers' in state.object_data) {
        setMissingValues(state.object_data.layers);
      }
    }
  }

  return state;
}

function updateFormatLayerSetting(layerId, formats, values, formatID = null) {
  for (const format of formats) {
    if (format.id === formatID) {
      for (const formatLayerId in format.object_data.layers) {
        if (layerId === formatLayerId) {
          format.object_data.layers[layerId].format = {
            ...format.object_data.layers[layerId].format,
            ...values
          };
        }
      }
    }
  }

  return formats;
}

function updateFormatLayerSettingAll(layerId, formats, values) {
  for (const format of formats) {
    for (const formatLayerId in format.object_data.layers) {
      if (layerId === formatLayerId) {
        if (
          'format' in format.object_data.layers[layerId] &&
          Object.keys(format.object_data.layers[layerId].format).length > 0
        ) {
          format.object_data.layers[layerId].format = {
            ...format.object_data.layers[layerId].format,
            ...values
          };
        }
      }
    }
  }

  return formats;
}

function recalculateGroups(state, formatID = '', valueChanged = {}) {
  let { formats } = state;
  const newLayers = state.object_data.layers;

  newLayers.forEach((layer) => {
    if (layer.layers?.length > 0 && layer.type === 'group') {
      for (const format of formats) {
        if (format.id === formatID) {
          for (const formatLayerID in format.object_data.layers) {
            if (layer.uuid === formatLayerID) {
              layer.format = {
                ...layer.format,
                ...format.object_data.layers[formatLayerID].format
              };
            }
          }
        }
      }

      let top = false;
      let left = false;
      let right = false;
      let bottom = false;

      for (let i = 0; i < layer.layers.length; i++) {
        const child = layer.layers[i];

        for (const format of formats) {
          if (format.id === formatID) {
            for (const formatLayerID in format.object_data.layers) {
              if (child.uuid === formatLayerID) {
                child.format = {
                  ...child.format,
                  ...format.object_data.layers[formatLayerID].format
                };
              }
            }
          }
        }

        let { x, y, width, height } = child.format;

        x = typeof x === 'string' ? parseInt(x) : x;
        y = typeof y === 'string' ? parseInt(y) : y;
        width = typeof width === 'string' ? parseInt(width) : width;
        height = typeof height === 'string' ? parseInt(height) : height;

        top = top === false ? y : Math.min(top, y);
        left = left === false ? x : Math.min(left, x);
        right = right === false ? x + width : Math.max(right, x + width);
        bottom = bottom === false ? y + height : Math.max(bottom, y + height);
      }

      const parentWidth = right - left;
      const parentHeight = bottom - top;

      // reassign new values to the group
      const groupIndex = getPathById(layer.uuid, newLayers);

      if (groupIndex.length === 1) {
        newLayers[groupIndex[0]].format = {
          ...newLayers[groupIndex[0]].format,
          x: parseInt(newLayers[groupIndex[0]].format.x) + left,
          y: parseInt(newLayers[groupIndex[0]].format.y) + top,
          width: parentWidth,
          height: parentHeight
        };

        formats = updateFormatLayerSetting(
          layer.uuid,
          formats,
          {
            x: newLayers[groupIndex[0]].format.x,
            y: newLayers[groupIndex[0]].format.y,
            width: newLayers[groupIndex[0]].format.width,
            height: newLayers[groupIndex[0]].format.height
          },
          formatID
        );
      }

      // Set children
      for (let i = 0; i < layer.layers.length; i++) {
        newLayers[groupIndex[0]].layers[i].format = {
          ...newLayers[groupIndex[0]].layers[i].format,
          x: newLayers[groupIndex[0]].layers[i].format.x - left,
          y: newLayers[groupIndex[0]].layers[i].format.y - top
        };

        formats = updateFormatLayerSetting(
          layer.layers[i].uuid,
          formats,
          {
            x: newLayers[groupIndex[0]].layers[i].format.x,
            y: newLayers[groupIndex[0]].layers[i].format.y
          },
          formatID
        );
      }
    }
  });

  return state;
}

function recalculateGroupOrder(state, oldObject, dragItem, formatID) {
  let { formats } = state;
  const newLayers = state.object_data.layers;
  const oldLayers = JSON.parse(JSON.stringify(oldObject));

  const newDragItemPath = getPathById(dragItem.uuid, newLayers);
  const oldDragItemPath = getPathById(dragItem.uuid, oldLayers);

  // Dont trigger if moved within the same group
  if (newDragItemPath.length === 2 && oldDragItemPath.length === 2) {
    if (newDragItemPath[0] === oldDragItemPath[0]) {
      return state;
    }

    const { x, y } = getPositionOnCanvas(dragItem, oldLayers);
    dragItem.format.x = x;
    dragItem.format.y = y;
  }

  // if we remove layer from group and insert it to root
  // or layer changed parent we find out the canvas position
  if (newDragItemPath.length === 1) {
    const { x, y } = getPositionOnCanvas(dragItem, oldLayers);

    dragItem.format.x = x;
    dragItem.format.y = y;
  }

  // if we remove layer from root and insert to group
  // or from group to another group
  // we calculate position of the layer relative to the parent group
  if (newDragItemPath.length === 2) {
    const parent = newDragItemPath.length === 2 && newLayers[newDragItemPath[0]];
    // get parents position
    const posX = parseInt(parent.format.x);
    const posY = parseInt(parent.format.y);
    // get layers position

    const childPosX = parseInt(dragItem.format.x);
    const childPosY = parseInt(dragItem.format.y);

    // recalculate
    const dx = childPosX - posX;
    const dy = childPosY - posY;

    // assign new value
    newLayers[newDragItemPath[0]].layers[newDragItemPath[1]].format.x = dx;
    newLayers[newDragItemPath[0]].layers[newDragItemPath[1]].format.y = dy;

    formats = updateFormatLayerSetting(
      newLayers[newDragItemPath[0]].layers[newDragItemPath[1]].uuid,
      formats,
      {
        x: dx,
        y: dy
      },
      formatID
    );

    dragItem.format.x = dx;
    dragItem.format.y = dy;
  }

  newLayers.forEach((layer) => {
    if (layer.uuid === dragItem.uuid) {
      layer.format = dragItem.format;

      formats = updateFormatLayerSetting(
        layer.uuid,
        formats,
        {
          x: dragItem.format.x,
          y: dragItem.format.y
        },
        formatID
      );
    }

    if (layer.layers?.length > 0) {
      // reset style
      let top = false;
      let left = false;
      let right = false;
      let bottom = false;

      for (let i = 0; i < layer.layers.length; i++) {
        const child = layer.layers[i];
        let { x, y, width, height } = child.format;

        width = typeof width === 'string' ? parseInt(width) : width;
        height = typeof height === 'string' ? parseInt(height) : height;

        top = top === false ? y : Math.min(top, y);
        left = left === false ? x : Math.min(left, x);
        right = right === false ? x + width : Math.max(right, x + width);
        bottom = bottom === false ? y + height : Math.max(bottom, y + height);
      }

      const parentWidth = right - left;
      const parentHeight = bottom - top;

      // reassign new values to the group
      const groupIndex = getPathById(layer.uuid, newLayers);
      if (groupIndex.length === 1) {
        newLayers[groupIndex[0]] = {
          ...newLayers[groupIndex[0]],
          format: {
            ...newLayers[groupIndex[0]].format,
            x: parseInt(newLayers[groupIndex[0]].format.x) + left,
            y: parseInt(newLayers[groupIndex[0]].format.y) + top,
            width: parentWidth,
            height: parentHeight
          }
        };

        formats = updateFormatLayerSetting(
          layer.uuid,
          formats,
          {
            x: newLayers[groupIndex[0]].format.x,
            y: newLayers[groupIndex[0]].format.y,
            width: parentWidth,
            height: parentHeight
          },
          formatID
        );
      }

      for (let i = 0; i < layer.layers.length; i++) {
        newLayers[groupIndex[0]].layers[i].format = {
          ...newLayers[groupIndex[0]].layers[i].format,
          x: newLayers[groupIndex[0]].layers[i].format.x - left,
          y: newLayers[groupIndex[0]].layers[i].format.y - top
        };

        formats = updateFormatLayerSetting(
          layer.layers[i].uuid,
          formats,
          {
            x: newLayers[groupIndex[0]].layers[i].format.x,
            y: newLayers[groupIndex[0]].layers[i].format.y
          },
          formatID
        );
      }
    }

    // Puts the group at 0x0 when no items in the group
    if (layer.type === 'group' && layer.layers?.length === 0) {
      const groupIndex = getPathById(layer.uuid, newLayers);
      if (groupIndex.length === 1) {
        newLayers[groupIndex[0]] = {
          ...newLayers[groupIndex[0]],
          format: {
            ...newLayers[groupIndex[0]].format,
            x: 0,
            y: 0,
            width: 0,
            height: 0
          }
        };

        formats = updateFormatLayerSettingAll(newLayers[groupIndex[0]].uuid, formats, {
          x: 0,
          y: 0,
          width: 0,
          height: 0
        });
      }
    }
  });

  return state;
}

function getAnimationEndTimes(animations) {
  const animationsTimes = [0];
  animations?.forEach((animation) =>
    animationsTimes.push(parseFloat(animation.time) * 100 + parseFloat(animation.duration) * 100)
  );
  return animationsTimes;
}

export const {
  getCreativeSet,
  saveCreativeSet,
  publishCreativeSet,
  getAd,
  saveAd,
  publishAd,
  getTemplate,
  setTemplate,
  setTemplateDuration,
  addFormat,
  removeFormat,
  setFormatName,
  addLayer,
  addGroupLayer,
  pasteLayer,
  deleteLayer,
  reorderLayers,
  setTemplateRepeats,
  setTemplateTitle,
  setTemplateClicktag,
  setTemplateLinkedClicktags,
  updateLayerName,
  updateLayerPosition,
  updateLayerSettings,
  updateLayerFormat,
  updateLayerEditorSettings,
  updateLayerFont,
  updateLayerImage,
  updateLayerImageState,
  updateLayerImageScale,
  applyImageToAllFormats,
  applyTextToAllFormats,
  updateLayerVideo,
  applyVideoToAllFormats,
  resetUndoHistory,
  undo,
  redo,
  updateLayerDCO,
  setTemplateDCO,
  addAnimation,
  deleteAnimation,
  updateAnimation,
  updateFormatLayerSettings,
  setTemplateRotateOnRepeat,
  setTemplateRepeatFrom,
  saveTemplate,
  setFormatSizeEstimate,
  addContentSpecificClicktag,
  updateContentSpecificClicktag,
  deleteContentSpecificClicktag,
  updateContentSpecificClicktagOnLayer,
  setCustomGuides,
  addCustomGuide,
  updateCustomGuide,
  removeCustomGuide,
  setPublic,
  addTag,
  setTags,
  removeTag
} = template.actions;
export const selectTemplate = (state) => state.template;
export default template.reducer;
