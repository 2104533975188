import React, { useEffect } from 'react';
import SentryWrapper from './containers/SentryWrapperContainer';
import StudioEditor from './containers/StudioEditorContainer';
import NewVersionChecker from './components/NewVersionChecker';
import StatusMessage from './components/StatusMessage';
import useAuthorization from '../utils/hooks/useAuthorization';
import { NoStudioPermission } from './components/NoStudioPermission';

function Editor(props) {
  const isAuthorized = useAuthorization();
  const { editorType } = props;

  // Only allow studio access if they use have studio permission. Others are still allowed to edit creative sets.
  if (editorType !== 'banner' && !isAuthorized({ permission: 'studio' })) {
    return <NoStudioPermission />;
  }

  return (
    <>
      <StatusMessage />
      <NewVersionChecker />
      <SentryWrapper>
        <StudioEditor actualEditorType={editorType} />
      </SentryWrapper>
    </>
  );
}

export default Editor;
