import { getDefaultLayerObject } from '../../objectsTypes';
import './AssetsMenu.scss';

export const getImageLayer = (image, selectedFormat) => {
  let layerWidth = image.width;
  let layerHeight = image.height;

  // Make sure the inserted image layer is not huge compared to the format.
  if (
    image.width > selectedFormat.object_data.width * 1.5 ||
    image.height > selectedFormat.object_data.height * 1.5
  ) {
    let imageSizeRatio = image.width / image.height;
    let widthRatio = image.width / selectedFormat.object_data.width;
    let heightRatio = image.height / selectedFormat.object_data.height;
    if (widthRatio > heightRatio) {
      layerWidth = selectedFormat.object_data.width * 1.5;
      layerHeight = (1 / imageSizeRatio) * layerWidth;
    } else {
      layerHeight = selectedFormat.object_data.height * 1.5;
      layerWidth = imageSizeRatio * layerHeight;
    }
  }
  let finalImage = getDefaultLayerObject('image');
  finalImage.format.width = layerWidth;
  finalImage.format.height = layerHeight;
  finalImage.settings.source = image;

  if (finalImage.settings.source.content_type !== 'image/svg+xml') {
    finalImage.settings.imageState = {
      crop: {
        x: 0,
        y: 0,
        width: image.width,
        height: image.height
      },
      cropAspectRatio: layerWidth / layerHeight,
      targetSize: {
        width: layerWidth,
        height: layerHeight
      }
    };
  }
  return finalImage;
};

export const getVideoLayer = (video, selectedFormat) => {
  let layerWidth = video.width;
  let layerHeight = video.height;

  // Make sure the inserted video layer is not huge compared to the format.
  if (
    video.width > selectedFormat.object_data.width * 1.5 ||
    video.height > selectedFormat.object_data.height * 1.5
  ) {
    let videoSizeRatio = video.width / video.height;
    let widthRatio = video.width / selectedFormat.object_data.width;
    let heightRatio = video.height / selectedFormat.object_data.height;
    if (widthRatio > heightRatio) {
      layerWidth = selectedFormat.object_data.width * 1.5;
      layerHeight = (1 / videoSizeRatio) * layerWidth;
    } else {
      layerHeight = selectedFormat.object_data.height * 1.5;
      layerWidth = videoSizeRatio * layerHeight;
    }
  }

  let finalVideo = getDefaultLayerObject('video');
  finalVideo.format.width = layerWidth;
  finalVideo.format.height = layerHeight;
  finalVideo.settings.source = video;
  finalVideo.settings.sound = false;
  finalVideo.animations = [
    {
      type: 'playVideo',
      time: 0,
      duration: parseFloat((Math.ceil((video.duration + 0.1) * 100) / 100).toFixed(2)),
      maxDuration: parseFloat((Math.ceil((video.duration + 0.1) * 100) / 100).toFixed(2))
    }
  ];

  return finalVideo;
};
