/* eslint-disable react/forbid-prop-types */
import React, { useEffect, useState, useRef } from 'react';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import Moment from 'moment';
import PropTypes from 'prop-types';
import {
  AdformLogo,
  CustomDCOIcon,
  DynamicLinkIcon,
  GoogleStudioLogo,
  MainDpaIcon,
  RaptorRetargetingLogo,
  UploadFileIcon,
  URLParameterIcon
} from '../../assets/icons';
import Menu from './Menu';
import usePosthogCapture from '../../../utils/hooks/usePosthogCapture';
import {
  chooseDco,
  getOverviewData,
  resetActiveMenu,
  resetSourceData,
  resetUserInput,
  setActiveMenu,
  setEditDatasourceModalOpened,
  setEditingDatasource,
  setUserInput,
  setEditingDatasourceScroll
} from '../../redux/slices/dco';
import BaseButton from '../common/BaseButton';
import Card from '../common/Card';
import { setLeftMenu } from '../../redux/slices/editorSession';
import theme from '../../assets/theme';

const HeaderWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  gap: 6px;
`;

const Headline = styled.span`
  font-size: 25px;
  line-height: 25px;
  color: #ffffff;
  width: 100%;
`;

const Subtitle = styled.span`
  color: #c4c4c4;
  font-weight: 300;
  font-size: 14px;
  line-height: 14px;
  width: 100%;
`;

const CreateSourceButton = styled.button`
  width: 224px;
  height: 30px;
  float: right;
  font-size: 12px;
  background: #b049ef;
  border-radius: 4px;
`;

const TopContentWrapper = styled.div`
  display: flex;
  place-content: space-between;
  padding-bottom: 10px;
  height: 30px;
`;

const ContentWrapper = styled.div`
  display: flex;
  flex-flow: wrap;
  max-height: calc(100% - 15px);
  overflow: scroll;
  /* Hide scrollbar for Chrome, Safari and Opera */
  ::-webkit-scrollbar {
    display: none;
  }
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
`;

const LeftChoiceButton = styled(BaseButton)`
  width: 75px !important;
  height: 30px !important;
  margin: 0px !important;
  border-radius: 3px 0px 0px 3px !important;
  background-color: ${(props) => (props.showCustomSource ? '' : props.theme.colors.dcoPurple)};
  border-left: ${(props) => (props.showCustomSource ? '1px solid #747474' : '')};
  border-top: ${(props) => (props.showCustomSource ? '1px solid #747474' : '')};
  border-bottom: ${(props) => (props.showCustomSource ? '1px solid #747474' : '')};
`;

const RightChoiceButton = styled(BaseButton)`
  width: 75px !important;
  height: 30px !important;
  margin: 0px !important;
  border-radius: 0px 3px 3px 0px !important;
  background-color: ${(props) => (props.showCustomSource ? props.theme.colors.dcoPurple : '')};
  border-right: ${(props) => (props.showCustomSource ? '' : '1px solid #747474')};
  border-top: ${(props) => (props.showCustomSource ? '' : '1px solid #747474')};
  border-bottom: ${(props) => (props.showCustomSource ? '' : '1px solid #747474')};
`;

const DisabledText = styled.span`
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 15px;

  align-items: center;
  text-align: center;

  color: rgba(255, 255, 255, 1);

  justify-content: center;
  vertical-align: middle;

  cursor: default;
  z-index: 1;
`;

const SelectSourceButton = styled(BaseButton)`
  font-weight: 600;
  top: 20px;
  border: 1px solid rgba(255, 255, 255, 0.6);
  background-color: rgba(25, 25, 25, 1);
`;

const ChangeSourceNameButton = styled(BaseButton)`
  font-weight: 600;
  top: 20px;
  border: 1px solid rgba(255, 255, 255, 0.6);
  background-color: rgba(25, 25, 25, 1);
  margin-top: 10px;
`;

function OverviewMenu(props) {
  const { modalRef } = props;
  const dispatch = useDispatch();
  const capturePosthogData = usePosthogCapture();

  const { leftMenu } = useSelector((state) => state.editorSession.menus);
  const { dcoSelection, activeMenu, userInput, overviewData } = useSelector((state) => state.dco);
  const { editingDatasourceScroll, editingDatasource } = useSelector((state) => state.dco);
  const [showCustomSource, setShowCustomSource] = useState(false);
  const isDco = leftMenu.dynamicLargeMenu === 'dco';
  const scrollRef = useRef(null);

  useEffect(() => {
    dispatch(getOverviewData());
    scrollRef.current.scrollTo(0, editingDatasourceScroll);
  }, []);

  useEffect(() => {
    scrollRef.current.scrollTo(0, editingDatasourceScroll);
  }, [editingDatasourceScroll, overviewData, editingDatasource]);

  // returns just the displayed overview data.
  // Will only return dpa if on dpa page, custom on custom page etc.
  const filteredOverviewData = () =>
    overviewData.filter(
      (data) =>
        leftMenu.dynamicLargeMenu === data.export &&
        (showCustomSource ? data.type === 'custom' : data.type !== 'custom')
    );

  const closeMenu = () => {
    dispatch(resetUserInput());
    dispatch(resetSourceData());
    dispatch(resetActiveMenu());
    dispatch(setEditingDatasourceScroll(0));

    dispatch(
      setLeftMenu({
        ...leftMenu,
        dynamicLargeMenu: ''
      })
    );
  };

  const getItemCount = overviewData.filter(
    (data) =>
      leftMenu.dynamicLargeMenu === data.export &&
      (showCustomSource ? data.type === 'custom' : data.type !== 'custom')
  ).length;

  function getSourceIcon(data) {
    if (data.export === 'dpa') {
      return <MainDpaIcon height='33' width='33' />;
    }
    if (data.type === 'file') {
      if (data.addon === 'adform') {
        return <AdformLogo height='33' width='33' viewBox='0 0 33 33' />;
      }

      return (
        <UploadFileIcon
          strokeArrow={theme.colors.dcoPurple}
          height='33'
          width='33'
          viewBox='0 0 33 33'
        />
      );
    }
    if (data.type === 'link') {
      if (data.addon === 'adform') {
        return <AdformLogo height='33' width='33' viewBox='0 0 33 33' />;
      }

      if (data.addon === 'google-studio') {
        return <GoogleStudioLogo height='33' width='33' viewBox='0 0 33 33' />;
      }

      if (data.addon === 'raptor') {
        return <RaptorRetargetingLogo height='33' width='33' viewBox='0 0 33 33' />;
      }

      return <DynamicLinkIcon height='33' width='33' viewBox='0 0 33 33' />;
    }
    if (data.type === 'parameter') {
      return <URLParameterIcon height='33' width='33' viewBox='0 0 33 33' />;
    }
    if (data.type === 'custom') {
      return <CustomDCOIcon height='33' width='33' viewBox='0 0 33 33' />;
    }

    return null;
  }

  function isSourceActive(id) {
    if (
      [
        dcoSelection.parameterSource.id,
        dcoSelection.dynamicSource.id,
        dcoSelection.customSource.id
      ].includes(id)
    ) {
      return true;
    }
    return false;
  }

  function isSourceDiabled(type) {
    if (
      (['parameter'].includes(type) && dcoSelection.parameterSourceIncluded) ||
      (['link', 'file'].includes(type) && dcoSelection.dynamicSourceIncluded) ||
      (['custom'].includes(type) &&
        (dcoSelection.customSourceIncluded ||
          dcoSelection.dynamicSourceIncluded ||
          dcoSelection.parameterSourceIncluded)) ||
      dcoSelection.customSourceIncluded
    ) {
      return true;
    }
    return false;
  }

  const Header = () => (
    <HeaderWrapper>
      <Headline>{isDco ? 'Dynamic Creative Optimization' : 'Catalog Ads'}</Headline>
      <Subtitle>{isDco ? 'Display' : 'Social Media'}</Subtitle>
    </HeaderWrapper>
  );

  const Toolbar = () => (
    <CreateSourceButton
      id='dco-create-new-datasource'
      onClick={() => {
        if (isDco) {
          capturePosthogData({ event: 'go-to-create-new-datasource-menu', type: 'dco' });
          dispatch(setActiveMenu({ ...activeMenu, source: 'dco', menu: 'create' }));
        } else {
          capturePosthogData({ event: 'go-to-create-new-dpa-source', type: 'dpa' });
          dispatch(
            setActiveMenu({
              ...activeMenu,
              menu: 'configure',
              source: 'dpa',
              activeOptionNumber: 0
            })
          );
          dispatch(setUserInput({ ...userInput, export: 'dpa', name: 'My Catalog Ads source' }));
        }
      }}>
      Create New Data Source
    </CreateSourceButton>
  );

  const ItemCountText = () => (
    <span style={{ alignSelf: 'center' }}>
      {getItemCount} item{getItemCount > 1 ? 's' : ''}
    </span>
  );

  const DcoTypeToggle = () => (
    <div
      style={{
        display: 'flex',
        borderRadius: '4px '
      }}>
      <LeftChoiceButton
        showCustomSource={showCustomSource}
        id='dco-sort-by-standard'
        onClick={() => {
          capturePosthogData({
            event: 'sort-by-standard',
            type: 'dco'
          });
          setShowCustomSource(false);
        }}
        text='Standard'
      />
      <RightChoiceButton
        showCustomSource={showCustomSource}
        id='dco-sort-by-custom'
        onClick={() => {
          capturePosthogData({
            event: 'sort-by-custom',
            type: 'dco'
          });
          setShowCustomSource(true);
        }}
        text='Custom'
      />
    </div>
  );

  const Content = () => (
    <>
      <TopContentWrapper>
        <ItemCountText />
        {isDco && <DcoTypeToggle />}
      </TopContentWrapper>
      <ContentWrapper ref={scrollRef}>
        {filteredOverviewData().map((data) => (
          <Card
            key={data.id}
            flex={2}
            title={data.name}
            description={data.description}
            icon={getSourceIcon(data)}
            updatedAt={Moment(data.updated_at).format('D MMM YYYY HH:mm')}
            hover={
              isSourceDiabled(data.type) ? (
                <DisabledText>
                  Only one {data.type} source allowed pr. template. <br />
                  Remove existing to select another.
                </DisabledText>
              ) : (
                <div>
                  <SelectSourceButton
                    text='Select source'
                    onClick={() => {
                      capturePosthogData({
                        event: 'select-source',
                        type: 'dco'
                      });
                      dispatch(chooseDco(data));
                      closeMenu();
                    }}
                    id='dco-select-source'
                    hoverColor={theme.colors.dcoPurple}
                  />
                  <ChangeSourceNameButton
                    text='Change source name'
                    onClick={(e) => {
                      e.preventDefault();
                      capturePosthogData({
                        event: 'change-source-name',
                        type: 'dco'
                      });
                      dispatch(setEditingDatasource(data));
                      dispatch(setEditDatasourceModalOpened(true));
                      dispatch(setEditingDatasourceScroll(scrollRef.current.scrollTop));
                    }}
                    id='dco-select-source'
                    hoverColor={theme.colors.dcoPurple}
                  />
                </div>
              )
            }
            disabled={isSourceDiabled(data.type)}
            active={isSourceActive(data.id)}
          />
        ))}
      </ContentWrapper>
    </>
  );

  return <Menu Header={Header} Toolbar={Toolbar} Content={Content} modalRef={modalRef} />;
}

OverviewMenu.defaultProps = {};

OverviewMenu.propTypes = {
  modalRef: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({ current: PropTypes.instanceOf(Element) })
  ]).isRequired
};

export default OverviewMenu;
