export function getDefaultLayerObject(layerType) {
  const layerObject = {
    uuid: '',
    name: '',
    editor_settings: {
      hidden: false,
      locked: false
    },
    settings: {},
    format: {
      x: 0,
      y: 0,
      z_index: 0,
      width: 0,
      height: 0,
      radius: 0,
      opacity: 100,
      rotation: 0,
      border: {
        color: 'rgba(255,255,255,1)',
        top: 0,
        right: 0,
        bottom: 0,
        left: 0
      },
      box_shadow: {
        x: 0,
        y: 0,
        color: 'rgba(255,255,255,1)',
        inset: false,
        blur: 0,
        spread: 0
      }
    }
  };
  switch (layerType) {
    case 'rectangle':
      layerObject.type = 'rectangle';
      layerObject.format.background_color = {
        palette: [
          {
            color: 'rgb(106,218,174)',
            opacity: 1,
            offset: '0'
          }
        ],
        angle: 90,
        colorType: 'solid'
      };
      layerObject.format.width = 162;
      layerObject.format.height = 100;
      break;
    case 'circle':
      layerObject.type = 'circle';
      layerObject.format.background_color = {
        palette: [
          {
            color: 'rgb(222,110,77)',
            opacity: 1,
            offset: '0'
          }
        ],
        angle: 90,
        colorType: 'solid'
      };
      layerObject.format.width = 100;
      layerObject.format.height = 100;
      layerObject.format.radius = 50;
      break;
    case 'text':
      layerObject.type = 'text';
      layerObject.format.width = 118;
      layerObject.format.height = 18;
      layerObject.format.line_height = 'inherit';
      layerObject.format.font_color = 'rgba(0,0,0,1)';
      layerObject.format.font_size = 15;
      layerObject.format.font_style = 'regular';
      layerObject.format.font_weight = 400;
      layerObject.format.text_shadow = {
        color: 'rgba(255,255,255,0)',
        x: 0,
        y: 0,
        blur: 0
      };
      layerObject.format.padding = {
        left: 0,
        right: 0,
        top: 0,
        bottom: 0
      };
      layerObject.format.text_transform = 'none';
      layerObject.format.letter_spacing = 0;
      layerObject.format.justify_content = 'start';
      layerObject.format.align_items = 'start';
      layerObject.format.display = 'flex';
      layerObject.format.flex_flow = 'column';
      layerObject.format.text_alignment = 'left';
      layerObject.format.text_overflow = 'overflow';
      layerObject.format.text_overflow_max_lines = 3;
      layerObject.settings.font = {
        name: 'Arial',
        uuid: '90d46c14-4d9a-45e1-9adc-2f9c48378c04',
        reference_type: 'web_safe'
      };
      layerObject.settings.text = [
        {
          type: 'paragraph',
          children: [
            {
              text: 'Write something'
            }
          ]
        }
      ];
      break;
    case 'image':
      layerObject.type = 'image';
      layerObject.format.background_size = 'contain';
      layerObject.dynamic = {
        dynamic_uuid: '',
        dynamic_key: 'None'
      };
      layerObject.settings.imageState = {
        crop: {},
        targetSize: {}
      };
      break;
    case 'video':
      layerObject.type = 'video';
      layerObject.format.background_size = 'contain';
      layerObject.dynamic = {
        dynamic_uuid: '',
        dynamic_key: 'None'
      };
      break;
    case 'group':
      layerObject.type = 'group';
      layerObject.layers = [];
      break;
  }

  return layerObject;
}
