/* eslint-disable no-restricted-syntax */
import { useDispatch, useSelector } from 'react-redux';
import { v4 as uuidv4 } from 'uuid';
import UseKeyboardShortcut from './HotkeyHandler';
import {
  rerenderSlate,
  rerenderSlateEditor,
  resetActiveAnimation,
  resetLeftMenu,
  resetRightMenu,
  setActiveLayer,
  setIsEditingOverlayImage,
  setScale,
  setSelectedLayers,
  toggleLayersHidden,
  stopAnimations,
  pauseAnimations,
  playAnimations
} from './studioEditor/redux/slices/editorSession';
import {
  addAnimation,
  addGroupLayer,
  deleteAnimation,
  deleteLayer,
  pasteLayer,
  redo,
  saveAd,
  saveTemplate,
  undo,
  updateLayerFormat,
  updateLayerPosition
} from './studioEditor/redux/slices/template';
import { getDefaultLayerObject } from './studioEditor/objectsTypes';
import { updateUserSettings } from './studioEditor/redux/slices/userSettings';
import { flat } from './helpers';

const HotkeyActions = ({ copiedData, setCopiedData }) => {
  const dispatch = useDispatch();

  const userId = useSelector((state) => state.auth.user.id);

  const { userSettings, template, editorSession } = useSelector((state) => state);
  const { id: templateId } = template;

  const {
    activeLayer,
    isEditingOverlayImage,
    selectedLayers,
    selectedFormatId,
    selectedFormat,
    editorType
  } = editorSession;

  const activeLayerFormat = useSelector((state) =>
    flat(state.editorSession.studio.presentLayers).find((layer) => layer.uuid === activeLayer)
  )?.format;

  const { scale, activeAnimation, animation } = useSelector((state) => state.editorSession.studio);
  const { rightMenu, leftMenu } = useSelector((state) => state.editorSession.menus);

  const { dcoSelection, editDatasourceModalOpened } = useSelector((state) => state.dco);

  const { quickExportBigMenuOpened, selectedQuickExportType } = useSelector(
    (state) => state.quickExport
  );
  const isModalOpenened = editDatasourceModalOpened || quickExportBigMenuOpened;

  const canUndo = template?._history?.undo.length > 0 ?? false;
  const canRedo = template?._history?.redo.length > 0 ?? false;

  function handleUpdateUserSettings(settingsType, changeFrom, changeTo) {
    dispatch(updateUserSettings({ userId, settingsType, changeFrom, changeTo }));
  }

  const findTemplateLayer = (layerId, layers) => {
    let foundLayer = null;
    const flatLayers = flat(layers);

    for (const layer of flatLayers) {
      if (layer.uuid === layerId) {
        foundLayer = layer;
        break;
      }
    }
    return foundLayer;
  };

  const findAnimation = (animationId, layer) => {
    let foundAnimation = null;
    foundAnimation = layer.animations.find((animation) => animation.uuid === animationId);
    return foundAnimation;
  };

  function handleCopyAndDuplicateAnimation(activeAnimation, activeLayer, eventType) {
    const newAnimationId = uuidv4();
    const layer = findTemplateLayer(activeLayer, template.object_data.layers);
    const duplicatedAnimationData = findAnimation(activeAnimation, layer);

    const newAnimation = {
      ...duplicatedAnimationData,
      uuid: newAnimationId
    };

    if (eventType === 'duplicate') {
      dispatch(addAnimation(selectedFormatId, activeLayer, newAnimation));
    }
    if (eventType === 'copy') {
      setCopiedData({ animation: duplicatedAnimationData });
    }
    if (eventType === 'paste' && copiedData.animation) {
      dispatch(
        addAnimation(selectedFormatId, activeLayer, {
          ...copiedData.animation,
          target: activeLayer,
          uuid: newAnimationId
        })
      );
    }
  }

  const copyLayer = (activeLayer) => {
    // Copy the layers state at the exact moment it's copied
    let copied = null;

    const layer = findTemplateLayer(activeLayer, template.object_data.layers);

    if (layer) {
      copied = {
        layer: JSON.parse(JSON.stringify(layer)),
        formats: JSON.parse(JSON.stringify(template.formats))
      };
      setCopiedData(copied);
    }
    return copied;
  };

  function onUpdateLayerFormat(layerId, formatData) {
    function checkForNullValues(list) {
      for (const [key, value] of Object.entries(list)) {
        if (value === '') {
          list[key] = '0';
        }

        if (typeof value === 'object') {
          checkForNullValues(value);
        }
      }
    }

    checkForNullValues(formatData);

    if ('x' in formatData || 'y' in formatData) {
      dispatch(updateLayerPosition(selectedFormatId, layerId, formatData));
    } else {
      dispatch(updateLayerFormat(selectedFormatId, layerId, formatData));
    }
  }

  // Zoom
  UseKeyboardShortcut(['+'], () => scale <= 390 && dispatch(setScale(scale + 10)), {
    overrideSystem: true,
    metaKeyPressed: true,
    overrideContentEditable: true
  });

  UseKeyboardShortcut(['-'], () => scale >= 20 && dispatch(setScale(scale - 10)), {
    overrideSystem: true,
    metaKeyPressed: true,
    overrideContentEditable: true
  });

  UseKeyboardShortcut(['0'], () => dispatch(setScale(100)), {
    overrideSystem: true,
    metaKeyPressed: true,
    overrideContentEditable: true
  });

  // Undo/Redo
  UseKeyboardShortcut(
    ['Z'],
    () => {
      if (canUndo) dispatch(undo());

      // We reset slate states to include possible changes
      dispatch(rerenderSlateEditor());
      dispatch(rerenderSlate());
    },
    {
      overrideSystem: true,
      metaKeyPressed: true,
      overrideInput: true
    }
  );

  UseKeyboardShortcut(
    ['Z'],
    () => {
      if (canRedo) dispatch(redo());

      // We reset slate states to include possible changes
      dispatch(rerenderSlateEditor());
      dispatch(rerenderSlate());
    },
    {
      overrideSystem: true,
      metaKeyPressed: true,
      shiftKeyPressed: true,
      overrideInput: true
    }
  );

  UseKeyboardShortcut(
    ['Y'],
    () => {
      if (canRedo) dispatch(redo());

      // We reset slate states to include possible changes
      dispatch(rerenderSlateEditor());
      dispatch(rerenderSlate());
    },
    {
      overrideSystem: true,
      metaKeyPressed: true,
      overrideInput: true
    }
  );

  // Save template
  UseKeyboardShortcut(
    ['S'],
    () => {
      if (editorType !== 'banner') {
        dispatch(saveTemplate());
      }
      if (editorType === 'banner' && templateId) {
        dispatch(saveAd());
      }
    },
    {
      overrideSystem: true,
      metaKeyPressed: true,
      overrideContentEditable: true
    }
  );

  // Copy/Paste/Duplicate Layers or Animations/Effects
  UseKeyboardShortcut(
    ['C'],
    () => {
      if (editorType !== 'banner' && activeLayer !== null && !activeAnimation.uuid) {
        copyLayer(activeLayer);
      }
      if (
        editorType !== 'banner' &&
        activeLayer !== null &&
        activeLayer !== undefined &&
        activeAnimation.uuid
      ) {
        handleCopyAndDuplicateAnimation(activeAnimation.uuid, activeLayer, 'copy');
      }
    },
    {
      overrideSystem: true,
      metaKeyPressed: true
    }
  );

  UseKeyboardShortcut(
    ['V'],
    () => {
      if (
        editorType !== 'banner' &&
        copiedData &&
        copiedData.layer &&
        copiedData.formats &&
        !copiedData.animation
      ) {
        dispatch(pasteLayer(copiedData, selectedFormat));
      }
      if (
        editorType !== 'banner' &&
        activeLayer !== null &&
        activeLayer !== undefined &&
        copiedData &&
        !copiedData.layer &&
        !copiedData.formats &&
        copiedData.animation
      ) {
        handleCopyAndDuplicateAnimation(copiedData.animation, activeLayer, 'paste');
      }
    },
    {
      overrideSystem: true,
      metaKeyPressed: true
    }
  );

  UseKeyboardShortcut(
    ['D'],
    () => {
      if (editorType !== 'banner' && activeLayer && !activeAnimation.uuid) {
        dispatch(pasteLayer(copyLayer(activeLayer), selectedFormat));
      }
      if (editorType !== 'banner' && activeLayer && activeAnimation.uuid) {
        handleCopyAndDuplicateAnimation(activeAnimation.uuid, activeLayer, 'duplicate');
      }
    },
    { overrideSystem: true, metaKeyPressed: true, overrideContentEditable: true }
  );

    // Space + Shift
    UseKeyboardShortcut([' '], () => {
      if (!isModalOpenened) {
        dispatch(stopAnimations());
      }
    }, 
    {
      shiftKeyPressed: true,
      overrideContentEditable: false,
      overrideInput: false
    });
    
    // Spacebar
    UseKeyboardShortcut([' '], () => {
      if (!isModalOpenened) {
        if (!animation.isPaused && animation.isPlaying) {
          dispatch(pauseAnimations());
        } else {
          dispatch(playAnimations());
      }
    }
    }, {
      overrideContentEditable: false,
      overrideInput: false
    });
  

  // Delete layers with Backspace
  UseKeyboardShortcut(
    ['Backspace'],
    () => {
      if (editorType !== 'banner' && activeLayer && !activeAnimation.uuid) {
        dispatch(deleteLayer(activeLayer));
        dispatch(setActiveLayer(null));
      }
      if (editorType !== 'banner' && activeAnimation && activeAnimation.uuid) {
        dispatch(deleteAnimation(selectedFormatId, activeAnimation.target, activeAnimation.uuid));
        dispatch(resetActiveAnimation());
      }
    },
    {}
  );

  // Delete layers with Delete
  UseKeyboardShortcut(
    ['Delete'],
    () => {
      if (editorType !== 'banner' && activeLayer && !activeAnimation.uuid) {
        dispatch(deleteLayer(activeLayer));
        dispatch(setActiveLayer(null));
      }
      if (editorType !== 'banner' && activeAnimation && activeAnimation.uuid) {
        dispatch(deleteAnimation(selectedFormatId, activeAnimation.target, activeAnimation.uuid));
        dispatch(resetActiveAnimation());
      }
    },
    {}
  );

  // Select all disable
  UseKeyboardShortcut(['A'], () => {}, {
    overrideSystem: true,
    metaKeyPressed: true,
    overrideContentEditable: false
  });

  // Create group - and add selected elements
  UseKeyboardShortcut(
    ['G'],
    () => {
      if (editorType !== 'banner') {
        const groupObject = getDefaultLayerObject('group');
        dispatch(addGroupLayer(groupObject, selectedFormat.id, selectedLayers));
        dispatch(setSelectedLayers([]));
      }
    },
    { overrideSystem: true, metaKeyPressed: true }
  );

  // Move layers
  UseKeyboardShortcut(
    ['ArrowDown'],
    () => {
      if (activeLayer && activeLayerFormat) {
        onUpdateLayerFormat(activeLayer, { y: activeLayerFormat.y + 1 });
      }
    },
    { overrideSystem: true, overrideContentEditable: false }
  ); // Move 1 pixel

  UseKeyboardShortcut(
    ['ArrowUp'],
    () => {
      if (activeLayer && activeLayerFormat) {
        onUpdateLayerFormat(activeLayer, { y: activeLayerFormat.y - 1 });
      }
    },
    { overrideSystem: true, overrideContentEditable: false }
  ); // Move 1 pixel

  UseKeyboardShortcut(
    ['ArrowLeft'],
    () => {
      if (activeLayer && activeLayerFormat) {
        onUpdateLayerFormat(activeLayer, { x: activeLayerFormat.x - 1 });
      }
    },
    { overrideSystem: true, overrideContentEditable: false }
  ); // Move 1 pixel

  UseKeyboardShortcut(
    ['ArrowRight'],
    () => {
      if (activeLayer && activeLayerFormat) {
        onUpdateLayerFormat(activeLayer, { x: activeLayerFormat.x + 1 });
      }
    },
    { overrideSystem: true, overrideContentEditable: false }
  ); // Move 1 pixel

  UseKeyboardShortcut(
    ['ArrowDown'],
    () => {
      if (activeLayer && activeLayerFormat) {
        onUpdateLayerFormat(activeLayer, { y: activeLayerFormat.y + 10 });
      }
    },
    { shiftKeyPressed: true, overrideSystem: true, overrideContentEditable: false }
  ); // Move 1 pixel

  UseKeyboardShortcut(
    ['ArrowUp'],
    () => {
      if (activeLayer && activeLayerFormat) {
        onUpdateLayerFormat(activeLayer, { y: activeLayerFormat.y - 10 });
      }
    },
    { shiftKeyPressed: true, overrideSystem: true, overrideContentEditable: false }
  ); // Move 1 pixel

  UseKeyboardShortcut(
    ['ArrowLeft'],
    () => {
      if (activeLayer && activeLayerFormat) {
        onUpdateLayerFormat(activeLayer, { x: activeLayerFormat.x - 10 });
      }
    },
    { shiftKeyPressed: true, overrideSystem: true, overrideContentEditable: false }
  ); // Move 1 pixel

  UseKeyboardShortcut(
    ['ArrowRight'],
    () => {
      if (activeLayer && activeLayerFormat) {
        onUpdateLayerFormat(activeLayer, { x: activeLayerFormat.x + 10 });
      }
    },
    { shiftKeyPressed: true, overrideSystem: true, overrideContentEditable: false }
  ); // Move 1 pixel

  // Deselect in this order: left menu -> right menu -> layer
  UseKeyboardShortcut(
    ['Escape'],
    () => {
      if (isEditingOverlayImage) {
        dispatch(setIsEditingOverlayImage(false));
      } else if (leftMenu.leftNavTabActive || leftMenu.activeButton !== '') {
        dispatch(resetLeftMenu());
      } else if (rightMenu) {
        dispatch(resetRightMenu());
      } else if (activeLayer !== '') {
        dispatch(setActiveLayer(null));
      }
    },
    { overrideSystem: true }
  );

  UseKeyboardShortcut(
    ['1'],
    () => {
      dispatch(toggleLayersHidden());
    },
    { overrideSystem: true, metaKeyPressed: true, overrideInput: true }
  );
  UseKeyboardShortcut(
    ['T'],
    () => {
      dispatch(toggleLayersHidden());
    },
    { shiftKeyPressed: true, overrideSystem: true }
  );
  UseKeyboardShortcut(
    ['9'],
    () => {
      const backgroundColors = ['#FFFFFF', '#BBBBBB', '#808080', '#4E4E4E', '#272727', '#000000'];
      const currentColorIndex = backgroundColors.indexOf(userSettings.background);
      if (currentColorIndex < backgroundColors.length - 1) {
        handleUpdateUserSettings(
          'background',
          userSettings.background,
          backgroundColors[currentColorIndex + 1]
        );
      } else if (currentColorIndex === backgroundColors.length - 1) {
        handleUpdateUserSettings('background', userSettings.background, backgroundColors[0]);
      }
    },
    { overrideSystem: true, metaKeyPressed: true }
  );
  UseKeyboardShortcut(
    ['2'],
    () => {
      if (userSettings.overflow === 'hidden') {
        handleUpdateUserSettings('overflow', userSettings.overflow, 'visible');
      } else if (userSettings.overflow === 'visible') {
        handleUpdateUserSettings('overflow', userSettings.overflow, 'hidden');
      }
    },
    { overrideSystem: true, metaKeyPressed: true, overrideInput: true }
  );
  UseKeyboardShortcut(
    ['O'],
    () => {
      if (userSettings.overflow === 'hidden') {
        handleUpdateUserSettings('overflow', userSettings.overflow, 'visible');
      } else if (userSettings.overflow === 'visible') {
        handleUpdateUserSettings('overflow', userSettings.overflow, 'hidden');
      }
    },
    { shiftKeyPressed: true, overrideSystem: true }
  );
  UseKeyboardShortcut(
    ['3'],
    () => {
      handleUpdateUserSettings('grid', userSettings.grid, !userSettings.grid);
    },
    { overrideSystem: true, metaKeyPressed: true, overrideInput: true }
  );
  UseKeyboardShortcut(
    ['G'],
    () => {
      handleUpdateUserSettings('grid', userSettings.grid, !userSettings.grid);
    },
    { shiftKeyPressed: true, overrideSystem: true }
  );
  UseKeyboardShortcut(
    ['4'],
    () => {
      handleUpdateUserSettings('guidelines', userSettings.guidelines, !userSettings.guidelines);
    },
    { overrideSystem: true, metaKeyPressed: true, overrideInput: true }
  );
  UseKeyboardShortcut(
    ['S'],
    () => {
      handleUpdateUserSettings('guidelines', userSettings.guidelines, !userSettings.guidelines);
    },
    { shiftKeyPressed: true, overrideSystem: true }
  );
  UseKeyboardShortcut(
    ['5'],
    () => {
      handleUpdateUserSettings('ruler', userSettings.ruler, !userSettings.ruler);
    },
    { overrideSystem: true, metaKeyPressed: true, overrideInput: true }
  );
  UseKeyboardShortcut(
    ['R'],
    () => {
      handleUpdateUserSettings('ruler', userSettings.ruler, !userSettings.ruler);
    },
    { shiftKeyPressed: true, overrideSystem: true }
  );
};

export default HotkeyActions;
